<template>
  <div
    class="field field--select"
    :style="{ '--form-item-width': grid.Cols, '--form-item-height': grid.Rows, '--form-item-start': grid.Offset }"
  >
    <label :for="id" class="field__label">
      <slot></slot>
      <font-awesome-icon v-if="description" icon="info-circle" class="info-icon" v-description="description" />
    </label>
    <div v-if="readonly" class="ui fluid search selection dropdown disabled">
      <i class="dropdown icon"></i>
      <input autocomplete="off" tabindex="-1" name="" class="search" />
      <div data-vss-custom-attr="" class="text">{{ readonlyValue }}</div>
    </div>
    <ModelListSelect
      v-else
      ref="select"
      :list="selectOptions"
      :value="JSON.stringify(forcevalue != null && forcevalue != false ? forcevalue : value)"
      :isDisabled="readonly"
      option-value="code"
      option-text="name"
      :filterPredicate="v => v"
      @searchchange="onSearch($event)"
      @input="onUpdate($event)"
      @keydown.native.enter.prevent="onEnter"
      @keydown.native.arrow-down="forceShowList = true"
      :class="{ 'hidden-list': hideList }"
    />
    <small v-if="error" style="color: red; font-style: italic; margin-left: 5px;">{{ error }}</small>
  </div>
</template>

<script>
import 'vue-search-select/dist/VueSearchSelect.css'
import { ModelListSelect } from 'vue-search-select'
export default {
  name: 'field-select',
  props: {
    error: String,
    grid: Object,
    id: String,
    name: String,
    description: String,
    options: Array,
    readonly: Boolean,
    value: [String, Boolean, Number],
    forcevalue: [String, Boolean, Number],
    searching: Boolean,
    listOnSearch: Boolean
  },
  data() {
    return {
      searchValue: '',
      forceShowList: false
    }
  },
  computed: {
    hideList() {
      if (this.forceShowList) return false
      return this.listOnSearch && !this.searchValue
    },
    readonlyValue() {
      // eslint-disable-next-line eqeqeq
      const v = JSON.stringify(this.forcevalue != null && this.forcevalue != false ? this.forcevalue : this.value)

      const foundValue = this.selectOptions.find(opt => {
        return opt.code === v
      })

      return typeof foundValue !== 'undefined' ? foundValue.name : ''
    },
    selectOptions() {
      return this.options.map(opt => ({
        code: JSON.stringify(opt.Value),
        name: opt.Title
      }))
    }
  },
  methods: {
    reset() {
      this.item = {}
    },
    onUpdate(newValue) {
      if (!newValue) return
      this.$emit('input', JSON.parse(newValue))
      this.$emit('search', '')
      // this.$emit('nextInput')
    },
    onSearch(search) {
      this.searchValue = search
      this.$emit('search', search)
    },
    onBlur() {
      this.forceShowList = false
    },
    onEnter(e) {
      // Set the selected item, if any
      let selected = this.$refs?.select?.$el?.querySelector('.menu .item.selected:not(:hover)')
      if (!selected && this.options.length === 1 && (this.value === null || this.value === '')) {
        selected = this.$refs?.select?.$el?.querySelector('.menu .item:first-child')
      }

      // If no item was selected, and we already have a value, move to the next input
      if (!selected && this.value) {
        this.$emit('nextInput')
        return
      }

      if (selected) {
        selected.click()
        this.$emit('nextInput')
      }
    },
    emitFirstOption() {
      if (!this.searchValue) return
      if (!this.options || this.options.length === 0) return
      this.$emit('input', this.options[0].Value)
    }
  },
  watch: {
    value() {
      if (!this.value) this.searchValue = ''
    }
  },
  mounted() {
    // get the dropdown icon 'dropdown icon' from refs.select
    const select = this.$refs.select
    const el = select?.$el
    if (!el) return
    const dropdownIcon = el.querySelector('.dropdown.icon')
    if (dropdownIcon) {
      // add a click event listener to the dropdown icon
      dropdownIcon.addEventListener('click', () => {
        this.forceShowList = true
      })
    }

    // Fix blur not propagating properly
    const input = el.querySelector('input')
    if (input) {
      input.addEventListener('blur', () => {
        this.onBlur()
        this.$emit('blur')
      })
    }

    // Watch for menu updates and unset the pointer
    // This is needed because we want to control what happens when the user presses enter
    const menu = el.querySelector('.menu')
    if (menu) {
      const observer = new MutationObserver(() => {
        if (!select.$children?.[0]) return
        select.$children[0].pointerSet = () => {} // Prevents 'mouseoverevent' from setting the pointer...
        select.$children[0].pointer = this.options.length === 1 ? 0 : -1
      })
      observer.observe(menu, {
        childList: true
      })
    }
  },
  components: {
    ModelListSelect
  }
}
</script>

<style scoped>
/* .field >>> .ui.selection.dropdown .menu > .item {
  font-family: monospace;
} */

.field >>> .ui.dropdown .menu .selected.item,
.field >>> .ui.dropdown.selected {
  background: rgba(0, 0, 0, 0.08);
}

.hidden-list >>> .menu {
  display: none !important;
}
</style>
