<template>
  <div
    class="field field--text"
    :style="{ '--form-item-width': grid.Cols, '--form-item-height': grid.Rows, '--form-item-start': grid.Offset }"
  >
    <label :for="id" class="field__label">
      <slot></slot>
      <font-awesome-icon v-if="description" icon="info-circle" class="info-icon" v-description="description" />
    </label>
    <small v-if="primarykey === 'new'" style="font-style: italic;"
      >Detta fält kan inte administreras innan du sparat</small
    >
    <dynamicTable
      v-else-if="listProvider && fieldsProvider"
      :listProvider="listProviderWithForeignKey"
      :fieldsProvider="fieldsProvider"
      :filtering="false"
      :showCreateButton="!readonly"
      :onRowClick="gotoSingle"
      :hiddenFields="hiddenFields"
      :linkKey="linkKey"
      :edit-row="!!linkKey"
      @create="gotoSingle('new')"
      @edit="gotoSingle($event, relatedto)"
    />
    <small v-if="error" style="color: red; font-style: italic; margin-left: 5px;">{{ error }}</small>
  </div>
</template>

<script>
import dynamicTable from '@/components/dynamicTable'

export default {
  name: 'field-one-to-many',
  props: {
    error: String,
    grid: Object,
    id: String,
    name: String,
    readonly: Boolean,
    primarykey: String,
    foreignkey: String,
    relatedto: String,
    relatedtocommon: Boolean,
    polymorphic: String,
    polymorphicid: String,
    polymorphiccommon: Boolean,
    linkKey: String,
    linkRelatedto: String,
    description: String,
    hiddenFields: {
      type: Array,
      default: () => []
    },
    filters: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    gotoSingle(primaryKey, type = this.relatedto) {
      // We use 'goto' to ensure we can safely navigate away from the form
      this.$emit('goto', () => {
        if (this.relatedtocommon) {
          this.$router.push({
            name: 'common-basregister-single',
            params: {
              type,
              trust: this.$route.params.trust,
              primaryKey: `${primaryKey}`
            },
            query: {
              relationKey: this.foreignkey,
              relationValue: this.primarykey,
              relationPolymorph: this.polymorphic ? this.$route.params.type : null,
              back: this.$route.path
            }
          })
        } else {
          this.$router.push({
            name: 'basregister-single',
            params: {
              type,
              trust: this.$route.params.trust,
              primaryKey: `${primaryKey}`
            },
            query: {
              relationKey: this.foreignkey,
              relationValue: this.primarykey,
              relationPolymorph: this.polymorphic ? this.$route.params.type : null,
              back: this.$route.path
            }
          })
        }
      })
    },
    listProviderWithForeignKey(query) {
      query.filters = query.filters || new Map()
      for (const filter of this.filters) {
        query.filters.set(filter.Query, [filter.Value])
      }

      if (this.polymorphic) {
        query.filters.set(`${this.foreignkey}Type`, [`==${this.$route.params.type}`])
        query.filters.set(`${this.foreignkey}ID`, [`==${this.primarykey}`])
      } else {
        query.filters.set(this.foreignkey, [`==${this.primarykey}`])
      }

      return this.listProvider(query)
    }
  },
  computed: {
    apiClient() {
      if (this.$store.getters.api === null) {
        return null
      }
      if (this.relatedtocommon) {
        return this.$store.getters.api.common()
      } else {
        return this.$store.getters.api.trust(this.$route.params.trust)
      }
    },
    listProvider() {
      if (this.apiClient === null) {
        return null
      }
      return this.apiClient.listProvider(this.relatedto)
    },
    fieldsProvider() {
      if (this.$store.getters.api === null) {
        return null
      }
      return this.apiClient.fieldsProvider(this.relatedto)
    }
  },
  components: {
    dynamicTable
  }
}
</script>
