<template>
  <div class="main">
    <dynamicTable v-if="listProvider && fieldsProvider"
                  :fullHeight="true"
                  :listProvider="listProvider"
                  :fieldsProvider="fieldsProvider"
                  :onRowClick="gotoSingle"
                  @create="gotoSingle('new')" />
    <h1 v-else>Okänt basregister - Välj ett basregister från sidomenyn</h1>
  </div>
</template>

<script>
import dynamicTable from '@/components/dynamicTable'

export default {
  name: 'basregister',
  props: {
    iscommon: Boolean
  },
  shortcuts: {
    cancel () {
      this.$router.push({
        name: 'home'
      })
    },
    newpage (e) {
      if (['INPUT', 'TEXTAREA', 'SELECT'].indexOf(e.target.nodeName) !== -1) {
        return
      }
      this.gotoSingle('new')
    }
  },
  methods: {
    gotoSingle (primaryKey, type = this.$route.params.type) {
      this.$router.push({
        name: this.iscommon ? 'common-basregister-single' : 'basregister-single',
        params: {
          type,
          trust: this.$route.params.trust,
          primaryKey: `${primaryKey}`
        }
      })
    }
  },
  computed: {
    listProvider () {
      if (this.apiClient === null) {
        return null
      }
      return this.apiClient
        .listProvider(this.$route.params.type)
    },
    fieldsProvider () {
      if (this.apiClient === null) {
        return null
      }
      return this.apiClient
        .fieldsProvider(this.$route.params.type)
    },
    apiClient () {
      if (this.iscommon) {
        return this.$store.getters.api.common()
      } else {
        return this.$store.getters.api.trust(this.$route.params.trust)
      }
    }
  },
  components: {
    dynamicTable
  }
}
</script>
