import Vue from 'vue'
import Vuex from 'vuex'
import session from '@/store/session'
import basregister from '@/store/basregister'
import notice from '@/store/notice'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    session,
    basregister,
    notice
  }
})
