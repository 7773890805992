import Vue from 'vue'
import AsyncComputed from 'vue-async-computed'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import App from './App.vue'
import router from './router'
import store from './store'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import moment from 'moment'
import 'moment/locale/sv'
import { Settings as LuxonSettings } from 'luxon'
import VueInputAutowidth from 'vue-input-autowidth'
import VueMoment from 'vue-moment'
import VueDatetime from 'vue-datetime'
import VMoney from 'v-money'
import VueTheMask from 'vue-the-mask'
import VueTelInput from 'vue-tel-input'
import VueKeybindings from './lib/vue-keybindings/src/Main'
import i18n from './i18n'

Vue.config.productionTip = false

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: 'https://9e9da99f52ef486fb11bfa11c52255e6@gt.duva.se/29',
    integrations: [new Integrations.Vue({ Vue, attachProps: true, logError: true })],
    beforeSend (event, hint) {
      // Check if it is an exception, and if so, show the report dialog
      // if (event.exception) {
      //   Sentry.showReportDialog({ eventId: event.event_id })
      // }
      return event
    }
  })
}

Vue.use(VueKeybindings, {
  alias: {
    cancel: ['esc'],
    reload: ['ctrl', 'r'],
    save: ['ctrl', 's'],
    remove: ['ctrl', 'backspace'],
    newpage: 'c'
  }
})

Vue.use(AsyncComputed,
  process.env.NODE_ENV !== 'development' ? {
    useRawError: true,
    errorHandler (err, vm, stack) {
      Sentry.captureException(err)
    }
  } : {
  }
)

Vue.use(VueMoment, { moment })
Vue.moment().locale('sv')

LuxonSettings.defaultLocale = 'sv'

Vue.use(VueInputAutowidth)
Vue.use(VueDatetime)
Vue.use(VMoney, { precision: 2 })
Vue.use(VueTheMask)
Vue.use(VueTelInput, { defaultCountry: 'se', dynamicPlaceholder: true, mode: 'international', disabledFetchingCountry: true })

library.add(fal)
library.add(far)
library.add(fas)
library.add(fad)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.directive('description', {
  // On click, this directive will display a popup with the description of the element
  bind (el, binding, vnode) {
    el.addEventListener('click', function () {
      // Attach div to body
      var div = document.createElement('div')
      div.className = 'description-popup'
      div.innerHTML = binding.value
      document.body.appendChild(div)

      // Position the div
      var rect = el.getBoundingClientRect()
      // var divRect = div.getBoundingClientRect()
      div.style.left = rect.left + 'px'
      div.style.top = rect.bottom + 'px'

      // Remove the div on click outside
      var remove = function (e) {
        if (!div.contains(e.target) && !el.contains(e.target)) {
          div.remove()
          document.removeEventListener('click', remove)
        }
      }

      document.addEventListener('click', remove)
    })
  }
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  data: {
    routeHistory: []
  }
}).$mount('#app')
