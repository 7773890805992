<template>
  <div class="columns" v-if="loading">
    <p>Var god vänta...</p>
  </div>
  <form class="columns" v-else @submit="login()">
    <p v-if="error">{{ error }}</p>
    <div class="column is-8 is-offset-2 is-10-mobile is-offset-1-mobile">
      <div class="field">
        <label class="label">Användarnamn</label>
        <div class="control">
          <input
            v-model="username"
            v-on:keyup.enter="login()"
            type="text"
            class="input"
            autocomplete="username email"
            required
          />
        </div>
      </div>
      <div class="field">
        <label class="label">Lösenord</label>
        <div class="control">
          <input
            v-model="password"
            v-on:keyup.enter="login()"
            type="password"
            class="input"
            autocomplete="current-password"
            required
          />
        </div>
      </div>
      <div class="field">
        <div class="control">
          <button class="button is-primary is-fullwidth" type="submit">Logga in</button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import BasicAuth from '@/api/auth/BasicAuth'

export default {
  name: 'password-auth',
  props: {},
  data() {
    return {
      api: new BasicAuth(),
      username: '',
      password: '',
      loading: false,
      error: null
    }
  },
  methods: {
    login() {
      this.loading = true
      this.api
        .login({ username: this.username, password: this.password })
        .then(res => {
          this.error = null
          this.$store.dispatch('newSession', res.data).then(() => {
            this.$router.push(this.$route.query.redirect_to ? this.$route.query.redirect_to : { name: 'home' })
          })
        })
        .catch(err => {
          this.loading = false
          this.error = err
        })
    }
  }
}
</script>

<style scoped lang="scss">
@font-face {
  font-family: 'Nexa';
  font-weight: 700;
  src: url('~@/assets/nexa/390B0B_0_0.eot');
  src: url('~@/assets/nexa/390B0B_0_0.eot?#iefix') format('embedded-opentype'),
    url('~@/assets/nexa/390B0B_0_0.woff2') format('woff2'), url('~@/assets/nexa/390B0B_0_0.woff') format('woff'),
    url('~@/assets/nexa/390B0B_0_0.ttf') format('truetype');
}
@font-face {
  font-family: 'Nexa';
  font-weight: 400;
  src: url('~@/assets/nexa/390B0B_1_0.eot');
  src: url('~@/assets/nexa/390B0B_1_0.eot?#iefix') format('embedded-opentype'),
    url('~@/assets/nexa/390B0B_1_0.woff2') format('woff2'), url('~@/assets/nexa/390B0B_1_0.woff') format('woff'),
    url('~@/assets/nexa/390B0B_1_0.ttf') format('truetype');
}

@import '~bulma/sass/utilities/_all';

// Set variables to overwrite Buefy
$family-sans-serif: 'Arial', sans-serif;

// Set your colors
$deep-sapphire: #012169;
$lochmara: #0085ca;
$calypso: #34657f;
$silver-chalice: #a7a8aa;

$primary: $deep-sapphire;
$primary-invert: findColorInvert($primary);
$info: $lochmara;
$info-invert: findColorInvert($info);

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
  'white': (
    $white,
    $black
  ),
  'black': (
    $black,
    $white
  ),
  'light': (
    $light,
    $light-invert
  ),
  'dark': (
    $dark,
    $dark-invert
  ),
  'primary': (
    $primary,
    $primary-invert
  ),
  'info': (
    $info,
    $info-invert
  ),
  'success': (
    $success,
    $success-invert
  ),
  'warning': (
    $warning,
    $warning-invert
  ),
  'danger': (
    $danger,
    $danger-invert
  )
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

@import '~bulma';
//@import "~buefy/src/scss/buefy";

* {
  font-family: $family-sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
.title {
  font-family: 'Nexa', sans-serif;
  font-weight: 700;
}

a:hover,
a:active,
a:focus {
  outline: 0;
}
.navbar-item::-moz-focus-inner {
  border: 0;
}
.has-light-border-top {
  border-top: 1px solid $grey-lighter;
}
.table {
  thead {
    td,
    th {
      &,
      & > * {
        font-family: 'Nexa', sans-serif;
      }
    }
  }
  &.is-unbordered {
    td,
    th {
      border-width: 0;
    }
  }
  &.is-aligned-middle {
    td,
    th {
      vertical-align: middle;
    }
  }
}
$deep-sapphire: #012169;

body {
  min-height: 100vh;
}
.columns--full-height,
.column--full-height {
  height: 100vh;
}
.columns--vertical-center {
  align-items: center;
}
.columns--no-vertical-margin {
  margin-bottom: 0;
  margin-top: 0;
  &:not(:last-child) {
    margin-bottom: 0;
  }
}
.columns--no-horizontal-margin {
  margin-left: 0;
  margin-right: 0;
}
.column--no-horizontal-padding {
  padding-left: 0;
  padding-right: 0;
}
.column--background-image {
  overflow: hidden;
}
.background-image {
  background: $deep-sapphire url('../../assets/images/login-background.jpg') no-repeat center center / cover;
  transition: filter 200ms linear;
}
.background-image--full {
  width: 120%;
  height: 120%;
  margin-top: -10%;
  margin-left: -10%;
}
.has-blur {
  filter: blur(5px);
}
.login__footer {
  padding-top: 20px;
  img {
    max-height: 1.75rem;
  }
}
.hand {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 50vw;
  transform: translateX(100%);
  transition: all 300ms ease;
  &.in-view {
    transform: translateX(0%);
  }
  &.is-faded {
    opacity: 0;
  }
}
$deep-sapphire: #012169;
$lochmara: #0085ca;
.is-bankid-field {
  position: relative;
  label {
    position: absolute;
    top: 20px;
    left: 14px;
    z-index: 90;
    font-size: 1.2rem;
    margin: 0;
    pointer-events: none;
    transform-origin: top left;
    transition: transform 150ms ease;
    font-weight: 400;
  }
  &.has-input {
    label {
      transform: scale(0.65) translateY(-15px);
    }
  }
  input {
    padding: 1.6rem 0.8rem 0.8rem 0.8rem;
    height: auto;
    font-size: 1.2rem;
    &::-ms-clear {
      display: none;
    }
  }
  .icon {
    width: 2.85em;
    height: 100%;
    &.is-right {
      right: 5px;
    }
  }
}
.icon-bankid {
  height: 1.5rem;
}

.button.is-fullwidth {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  height: auto;
}

.progress {
  height: 1rem;
  width: 100%;
  background: #333;
  border-radius: 0px;
  background: radial-gradient(circle at center, $lochmara 0, $deep-sapphire 100%) repeat center center;
  margin-bottom: 0px;
  animation: progress 5s linear infinite;
  @media screen and (max-width: 768px) {
    animation: progress-full-width 5s linear infinite;
  }
}
.info-box {
  padding: 3rem 0;
  background: #f6fcff;
  .bankid-text {
    color: $deep-sapphire;
    display: flex;
    align-items: center;
    font-weight: 800;
    font-size: 0.9rem;
    span {
      font-family: 'Nexa', sans-serif;
    }
    .icon {
      margin-right: 0.7rem;
      width: 2em;
      height: 2em;
    }
  }
}
label {
  padding-left: 0;
}
@keyframes progress {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 50vw 0;
  }
}
@keyframes progress-full-width {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100vw 0;
  }
}
</style>
