<template>
  <div
    class="field field--text"
    :style="{ '--form-item-width': grid.Cols, '--form-item-height': grid.Rows, '--form-item-start': grid.Offset }"
  >
    <label :for="id" class="field__label">
      <slot></slot>
    </label>
    <money
      v-if="money"
      :value="cValue !== null ? parseFloat(cValue) : 0.0"
      :disabled="disabled"
      :placeholder="placeholder"
      ref="money"
      @keydown.native="updatePrecision"
      @input="update($event)"
      @keydown.native.enter.prevent="$emit('nextInput')"
      :masked="false"
      decimal=","
      thousands=" "
      suffix=" kr"
      :precision="precision"
    />
    <input
      v-else
      :disabled="disabled"
      :placeholder="placeholder"
      :readonly="readonly"
      :type="'text'"
      :value="cValue"
      @input="update($event.target.value)"
      @keydown.enter.prevent="$emit('nextInput')"
    />
    <small v-if="error" style="color: red; font-style: italic">{{ error }}</small>
    <small v-if="amountOfDecimals > 2" style="color: #f28800; font-style: italic"
      >Visar avrundning. Databas lagrar fler än två decimaler på detta värde</small
    >
  </div>
</template>

<script>
export default {
  name: 'field-decimal',
  props: {
    error: String,
    grid: Object,
    disabled: Boolean,
    money: Boolean,
    id: String,
    name: String,
    placeholder: String,
    readonly: Boolean,
    type: String,
    value: [String, Number, null]
  },
  data() {
    if (this.money && this.disabled) {
      return {
        precision: 2,
        newValue: this.value
      }
    }
    return {
      precision: 0,
      newValue: this.value
    }
  },
  methods: {
    update(newValue) {
      if (newValue !== null && newValue !== undefined) {
        this.newValue = `${newValue}`.replace?.(',', '.')
        this.$emit('input', this.newValue)
      }
    },
    updatePrecision(e) {
      if (this.precision !== 2 && (e.key === ',' || e.key === '.')) {
        this.precision = 2
      }
    }
  },
  computed: {
    cValue() {
      if (this.newValue?.endsWith('.')) {
        return this.newValue
      }

      return this.value
    },
    amountOfDecimals() {
      if (!this.value) {
        return 0
      }
      return (
        Number(this.value)
          .toString()
          .split('.')[1] || ''
      ).length
    }
  }
}
</script>
