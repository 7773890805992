<template>
  <div
    class="field field--text"
    :style="{ '--form-item-width': grid.Cols, '--form-item-height': grid.Rows, '--form-item-start': grid.Offset }"
  >
    <label :for="id" class="field__label">
      <slot></slot>
    </label>
    <input
      v-if="type === 'bool'"
      :disabled="disabled"
      :readonly="readonly"
      type="checkbox"
      :value="value"
      @input="update($event.target.value)"
      @keydown.enter.prevent="$emit('nextInput')"
    />
    <div v-if="type === 'url' && readonly">
      <a class="button is-primary" target="_BLANK" :href="value">Öppna</a>
    </div>
    <div v-else-if="mask === 'ssn'">
      <the-mask
        :mask="['######-####', '########-####']"
        :value="value"
        :disabled="disabled"
        :placeholder="placeholder"
        @input="update($event)"
        @keydown.enter.native.prevent="$emit('nextInput')"
        @blur.native="onBlurSSN($event.target.value)"
        :masked="false"
      />
    </div>
    <div v-else-if="mask === 'orgno'">
      <the-mask
        :mask="['######-####']"
        :value="value"
        :disabled="disabled"
        :placeholder="placeholder"
        @input="update($event)"
        @keydown.enter.native.prevent="$emit('nextInput')"
        :masked="false"
      />
    </div>
    <div v-else-if="mask === 'phone'">
      <vue-tel-input
        :value="value"
        :disabled="disabled || readonly"
        @input="update($event)"
        @keydown.enter.native.prevent="$emit('nextInput')"
      />
    </div>
    <div v-else-if="mask === 'month-and-day'">
      <input
        :disabled="disabled"
        :id="id"
        :name="name"
        placeholder="Ex: 25/3"
        :readonly="readonly"
        :type="type === 'number' ? 'number' : 'text'"
        :value="value"
        @input="update($event.target.value)"
        @keydown.enter.prevent="$emit('nextInput')"
      />
    </div>
    <div v-else-if="mask === 'zipcode'">
      <the-mask
        :mask="['####', '### ##', '#####-####']"
        :value="value"
        :disabled="disabled"
        :placeholder="placeholder"
        @input="update($event)"
        @keydown.enter.native.prevent="$emit('nextInput')"
        :masked="false"
      />
    </div>
    <input
      v-else
      :disabled="disabled"
      :id="id"
      :name="name"
      :placeholder="placeholder"
      :readonly="readonly"
      :type="type === 'number' ? 'number' : 'text'"
      :value="value"
      @input="update($event.target.value)"
      @keydown.enter.prevent="$emit('nextInput')"
    />
    <small v-if="error" style="color: red; font-style: italic">{{ error }}</small>
  </div>
</template>

<script>
export default {
  name: 'field-input',
  props: {
    error: String,
    grid: Object,
    disabled: Boolean,
    id: String,
    name: String,
    placeholder: String,
    readonly: Boolean,
    type: String,
    mask: String,
    value: [String, Boolean, Number]
  },
  methods: {
    update(newValue) {
      if (this.type === 'number') {
        newValue = parseInt(newValue)
      }
      this.$emit('input', newValue)
    },
    onBlurSSN(newValue) {
      let ssn = newValue.replace(/\D/g, '')
      if (ssn.length === 10) {
        ssn = '19' + ssn
      } else {
        ssn = ssn.substring(0, 12)
      }

      this.$emit('input', ssn)
    }
  }
}
</script>

<style scoped lang="scss">
//@import url("//hello.myfonts.net/count/390b0b");
@font-face {
  font-family: 'Nexa';
  font-weight: 700;
  src: url('~@/assets/nexa/390B0B_0_0.eot');
  src: url('~@/assets/nexa/390B0B_0_0.eot?#iefix') format('embedded-opentype'),
    url('~@/assets/nexa/390B0B_0_0.woff2') format('woff2'), url('~@/assets/nexa/390B0B_0_0.woff') format('woff'),
    url('~@/assets/nexa/390B0B_0_0.ttf') format('truetype');
}
@font-face {
  font-family: 'Nexa';
  font-weight: 400;
  src: url('~@/assets/nexa/390B0B_1_0.eot');
  src: url('~@/assets/nexa/390B0B_1_0.eot?#iefix') format('embedded-opentype'),
    url('~@/assets/nexa/390B0B_1_0.woff2') format('woff2'), url('~@/assets/nexa/390B0B_1_0.woff') format('woff'),
    url('~@/assets/nexa/390B0B_1_0.ttf') format('truetype');
}

@import '~bulma/sass/utilities/_all';

// Set variables to overwrite Buefy
$family-sans-serif: 'Arial', sans-serif;

// Set your colors
$deep-sapphire: #012169;
$lochmara: #0085ca;
$calypso: #34657f;
$silver-chalice: #a7a8aa;

$primary: $deep-sapphire;
$primary-invert: findColorInvert($primary);
$info: $lochmara;
$info-invert: findColorInvert($info);

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
  'white': (
    $white,
    $black
  ),
  'black': (
    $black,
    $white
  ),
  'light': (
    $light,
    $light-invert
  ),
  'dark': (
    $dark,
    $dark-invert
  ),
  'primary': (
    $primary,
    $primary-invert
  ),
  'info': (
    $info,
    $info-invert
  ),
  'success': (
    $success,
    $success-invert
  ),
  'warning': (
    $warning,
    $warning-invert
  ),
  'danger': (
    $danger,
    $danger-invert
  )
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

@import '~bulma';
</style>
