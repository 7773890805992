const getDefaultState = () => {
  return {
    currentTrust: null
  }
}

const state = getDefaultState()

export default {
  state,
  mutations: {
    setCurrentTrust (state, trust) {
      state.currentTrust = trust
    }
  },
  actions: {
    currentTrust ({ commit, dispatch, getters }, trustSlug) {
      getters.api.common().trusts()
        .then(resp => {
          resp.data.trusts.forEach(trust => {
            if (trust.Slug === trustSlug) {
              commit('setCurrentTrust', trust)
            }
          })
        })
    }
  },
  getters: {
    currentTrustName: state => state.currentTrust != null ? state.currentTrust.Name : '---'
  }
}
