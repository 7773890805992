<template>
  <div v-if="state == availStates.START && !isMobile" class="columns">
    <div class="column is-8 is-offset-2 is-10-mobile is-offset-1-mobile">
      <div class="field">
        <p class="control mb-2">
          <button class="button is-medium is-primary" @click="startAuth(false)">Mobilt BankID</button>
        </p>
        <p class="control">
          <button class="button is-medium is-primary" @click="startAuth(true)">BankID på den här datorn</button>
        </p>
      </div>
    </div>
  </div>
  <div v-else-if="state == availStates.START && isMobile" class="columns">
    <div class="column is-12 is-10-mobile is-offset-1-mobile">
      <div class="field">
        <p class="control mb-2">
          <button class="button is-medium is-primary is-fullwidth" @click="startAuth(true)">
            BankID på den här enheten
          </button>
        </p>
        <p class="control">
          <button class="button is-medium is-primary is-fullwidth" @click="startAuth(false)">
            BankID på en annan enhet
          </button>
        </p>
      </div>
    </div>
  </div>
  <div v-else-if="state == availStates.PENDING">
    <div class="progress"></div>
    <div class="info-box">
      <div class="columns">
        <div class="column is-8 is-offset-2 is-10-mobile is-offset-1-mobile">
          <a class="bankid-text" :href="autoStartTokenUrl"
            ><span class="icon"><img src="@/assets/images/bankid.svg"/></span> <span>{{ message }}</span></a
          >
        </div>
      </div>
    </div>
    <iframe :src="autoStartTokenUrl" style="height: 0;" />
  </div>
  <div v-else-if="state == availStates.PENDING_SHOW_QR || true">
    <div class="progress"></div>
    <div class="info-box">
      <div class="columns">
        <div class="column is-8 is-offset-2 is-10-mobile is-offset-1-mobile">
          <span class="bankid-text"
            ><span class="icon"><img src="@/assets/images/bankid.svg"/></span> <span>{{ message }}</span></span
          >
        </div>
      </div>
      <div class="columns">
        <div class="has-text-centered column is-8 is-offset-2 is-10-mobile is-offset-1-mobile">
          <img :src="autoStartTokenQrImage" />
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="state == availStates.FAILED" class="columns">
    <div class="column is-8 is-offset-2 is-10-mobile is-offset-1-mobile">
      <bulma-message title="Fel vid inloggning" type="is-danger" :closable="false">
        {{ message }}
      </bulma-message>
    </div>
  </div>
  <div v-else-if="state == availStates.SUCCESS" class="columns">
    <div class="column is-8 is-offset-2 is-10-mobile is-offset-1-mobile">
      <bulma-message title="Inloggad" type="is-success" :closable="false">
        {{ message }}
      </bulma-message>
    </div>
  </div>
</template>

<script>
import BankID from '@/api/auth/BankID'
import isMobile from 'is-mobile'
import bulmaMessage from '@/components/bulmaMessage'

const availStates = {
  START: 1,
  PENDING: 2,
  PENDING_SHOW_QR: 3,
  FAILED: 4,
  SUCCESS: 5
}

export default {
  name: 'bank-id-auth',
  components: {
    bulmaMessage
  },
  props: {
    debug: {
      type: Boolean,
      default: false
    },
    mobile: {
      type: Boolean,
      default: isMobile({ ua: navigator.userAgent, tablet: true })
    },
    pNumber: {
      type: String,
      default: ''
    },
    collectInterval: {
      type: Number,
      default: 2000
    }
  },
  data() {
    return {
      api: new BankID(),
      collector: null,
      personNumber: this.pNumber,
      isMobile: this.mobile,
      bankIdFocus: false,
      showLoginError: false,
      state: availStates.START,
      message: 'Identifiering eller underskrift pågår',
      autoStartToken: '',
      autoStartTokenUrl: '',
      autoStartTokenQrImage: ''
    }
  },
  computed: {
    showIsError: {
      get: function() {
        if (this.validPersonNumber) return false
        return this.showLoginError
      },
      set: function(newValue) {
        this.showLoginError = newValue
      }
    },
    availStates: function() {
      return availStates
    }
  },
  methods: {
    startCollector() {
      if (this.collector !== null) {
        throw new Error('collector is already running')
      }
      const self = this
      this.collector = setInterval(() => {
        this.api
          .collect({
            orderRef: self.orderRef,
            device: self.isMobile ? 'mobile' : 'computer',
            method: self.method
          })
          .then(r => {
            if (r.status === 200) {
              switch (r.data.collectStatus) {
                case 'complete':
                  this.stopCollector()
                  this.message = 'Success'
                  this.state = availStates.SUCCESS
                  this.$store
                    .dispatch('newSession', {
                      token: r.data.token,
                      account: r.data.account
                    })
                    .then(() => {
                      this.$router.push(
                        this.$route.query.redirect_to ? this.$route.query.redirect_to : { name: 'home' }
                      )
                    })
                  break
                case 'pending':
                  if (r.data.userMessage) {
                    this.message = r.data.userMessage.Swedish
                  }
                  break
                case 'failed':
                default:
                  this.stopCollector()
                  if (r.data.userMessage) {
                    this.message = r.data.userMessage.Swedish
                  }
                  this.state = availStates.FAILED
                  break
              }
            } else {
              this.stopCollector()
              this.message = 'Unknown error. Please try again.'
              this.state = availStates.FAILED
            }
          })
          .catch(() => {
            this.stopCollector()
            this.message = 'Unknown error. Please try again.'
            this.state = availStates.FAILED
          })
      }, self.collectInterval)
    },
    stopCollector() {
      if (this.collector === null) {
        throw new Error('collector is not running')
      }
      clearInterval(this.collector)
      this.collector = null
    },
    startAuth(thisDevice, personNumber = null) {
      if (personNumber !== null && !this.validPersonNumber) {
        this.showIsError = true
        return
      }
      this.autoStart = thisDevice
      this.autoStartToken = null
      this.autoStartTokeUrl = null
      this.autoStartTokenQrImage = null

      switch (true) {
        case thisDevice:
          this.method = 'auto'
          break
        case personNumber === null:
          this.method = 'qr'
          break
        default:
          this.method = 'manual'
          break
      }
      this.state = this.method === 'qr' ? availStates.PENDING_SHOW_QR : availStates.PENDING

      this.api
        .auth({
          autoStart: this.autoStart,
          device: this.isMobile ? 'mobile' : 'computer',
          method: this.method
        })
        .then(r => {
          if (r.status !== 200) {
            this.message = 'Unknown error. Please try again.'
            this.state = availStates.FAILED
            return
          }
          this.orderRef = r.data.orderRef
          if (this.method === 'qr') {
            this.autoStartTokenQrImage = r.data.autoStartTokenQrImage
          } else if (this.method === 'auto') {
            this.autoStartToken = r.data.autoStartToken
            var url = 'bankid:///?autostarttoken=' + this.autoStartToken + '&redirect=null'
            if (navigator.platform.match(/iPhone|iPod|iPad/)) {
              url = 'https://app.bankid.com/?autostarttoken=' + this.autoStartToken + '&redirect=null'
            }

            this.autoStartTokenUrl = url
          } else if (this.method === 'manual') {
            // Manual
          }
          this.startCollector()
        })
        .catch(() => {
          this.message = 'Unknown error. Please try again.'
          this.state = availStates.FAILED
        })
    }
  }
}
</script>

<style scoped lang="scss">
//@import url("//hello.myfonts.net/count/390b0b");
@font-face {
  font-family: 'Nexa';
  font-weight: 700;
  src: url('~@/assets/nexa/390B0B_0_0.eot');
  src: url('~@/assets/nexa/390B0B_0_0.eot?#iefix') format('embedded-opentype'),
    url('~@/assets/nexa/390B0B_0_0.woff2') format('woff2'), url('~@/assets/nexa/390B0B_0_0.woff') format('woff'),
    url('~@/assets/nexa/390B0B_0_0.ttf') format('truetype');
}
@font-face {
  font-family: 'Nexa';
  font-weight: 400;
  src: url('~@/assets/nexa/390B0B_1_0.eot');
  src: url('~@/assets/nexa/390B0B_1_0.eot?#iefix') format('embedded-opentype'),
    url('~@/assets/nexa/390B0B_1_0.woff2') format('woff2'), url('~@/assets/nexa/390B0B_1_0.woff') format('woff'),
    url('~@/assets/nexa/390B0B_1_0.ttf') format('truetype');
}

@import '~bulma/sass/utilities/_all';

// Set variables to overwrite Buefy
$family-sans-serif: 'Arial', sans-serif;

// Set your colors
$deep-sapphire: #012169;
$lochmara: #0085ca;
$calypso: #34657f;
$silver-chalice: #a7a8aa;

$primary: $deep-sapphire;
$primary-invert: findColorInvert($primary);
$info: $lochmara;
$info-invert: findColorInvert($info);

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
  'white': (
    $white,
    $black
  ),
  'black': (
    $black,
    $white
  ),
  'light': (
    $light,
    $light-invert
  ),
  'dark': (
    $dark,
    $dark-invert
  ),
  'primary': (
    $primary,
    $primary-invert
  ),
  'info': (
    $info,
    $info-invert
  ),
  'success': (
    $success,
    $success-invert
  ),
  'warning': (
    $warning,
    $warning-invert
  ),
  'danger': (
    $danger,
    $danger-invert
  )
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

@import '~bulma';
//@import "~buefy/src/scss/buefy";

* {
  font-family: $family-sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
.title {
  font-family: 'Nexa', sans-serif;
  font-weight: 700;
}

a:hover,
a:active,
a:focus {
  outline: 0;
}
.navbar-item::-moz-focus-inner {
  border: 0;
}
.has-light-border-top {
  border-top: 1px solid $grey-lighter;
}
.table {
  thead {
    td,
    th {
      &,
      & > * {
        font-family: 'Nexa', sans-serif;
      }
    }
  }
  &.is-unbordered {
    td,
    th {
      border-width: 0;
    }
  }
  &.is-aligned-middle {
    td,
    th {
      vertical-align: middle;
    }
  }
}
$deep-sapphire: #012169;
$lochmara: #0085ca;
.is-bankid-field {
  position: relative;
  label.label {
    position: absolute;
    top: 23px;
    left: 14px;
    z-index: 90;
    font-size: 1.2rem;
    margin: 0;
    pointer-events: none;
    transform-origin: top left;
    transition: transform 150ms ease;
    font-weight: 400;
    padding: 0;
  }
  &.has-input {
    label {
      transform: scale(0.65) translateY(-15px);
    }
  }
  input {
    padding: 1.6rem 0.8rem 0.8rem 0.8rem;
    height: auto;
    font-size: 1.2rem;
    &::-ms-clear {
      display: none;
    }
  }
  .control .icon {
    width: 2.85em;
    height: 100%;
    &.is-right {
      right: 5px;
    }
  }
}
.icon-bankid {
  height: 1.5rem;
}

.button.is-fullwidth {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  height: auto;
}

.progress {
  height: 1rem;
  width: 100%;
  background: #333;
  border-radius: 0px;
  background: radial-gradient(circle at center, $lochmara 0, $deep-sapphire 100%) repeat center center;
  margin-bottom: 0px;
  animation: progress 5s linear infinite;
  @media screen and (max-width: 768px) {
    animation: progress-full-width 5s linear infinite;
  }
}
.info-box {
  padding: 3rem 0;
  background: #f6fcff;
  .bankid-text {
    color: $deep-sapphire;
    display: flex;
    align-items: center;
    font-weight: 800;
    font-size: 0.9rem;
    span {
      font-family: 'Nexa', sans-serif;
    }
    .icon {
      margin-right: 0.7rem;
      width: 2em;
      height: 2em;
    }
  }
}
@keyframes progress {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 50vw 0;
  }
}
@keyframes progress-full-width {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 100vw 0;
  }
}
</style>
