<template>
  <div
    class="field field--select"
    :style="{ '--form-item-width': grid.Cols, '--form-item-height': grid.Rows, '--form-item-start': grid.Offset }"
  >
    <label :for="id" class="field__label">
      <slot></slot>
    </label>
    <span class="text" :style="{ background: stateColors.get(value) }">{{ currentState }}</span>
    <small v-if="error" style="color: red; font-style: italic; margin-left: 5px;">{{ error }}</small>
  </div>
</template>

<script>
export default {
  name: 'field-state',
  props: {
    error: String,
    grid: Object,
    id: String,
    name: String,
    options: Array,
    states: Object,
    value: [String, Boolean, Number],
    forcevalue: [String, Boolean, Number]
  },
  computed: {
    currentState() {
      return this.options?.find(option => option.Value === this.value)?.Title ?? this.value
    },
    stateTransitions() {
      return this.states?.[this.value] ?? []
    },
    stateColors() {
      var states = new Map()

      for (const option of this.options) {
        if (option.Color) {
          let c = option.Color
          if (c === 'red') {
            c = '#FAE5E5'
          } else if (c === 'green') {
            c = 'rgba(0, 255, 7, 0.25)'
          } else if (c === 'blue') {
            c = 'rgba(0, 114, 255, 0.25)'
          } else if (c === 'yellow') {
            c = 'rgba(255, 247, 0, 0.25)'
          } else if (c === 'gray') {
            c = 'rgba(0, 0, 0, 0.25)'
          }
          states.set(option.Value, c)
        }
      }

      return states
    }
  },
  methods: {
    onUpdate(newValue) {
      this.$emit('input', newValue)
    }
  },
  components: {}
}
</script>

<style lang="scss" scoped>
.text {
  display: inline-block;
  // color: #fff;
  background-color: var(--green);
  border-radius: 1em;
  border: 0;
  // padding: 0.5rem;
  padding: 0.5em 0.75em;
  // margin-bottom: 1rem;
}
</style>
