<template>
  <div
    class="field field--select"
    :style="{
      '--form-item-width': grid.Cols,
      '--form-item-height': grid.Rows,
      '--form-item-start': grid.Offset,
      display: 'flex',
      justifyContent: 'flex-start'
    }"
  >
    <button
      v-for="button in buttons"
      :key="button.Key + button.Value"
      :class="['button', button.Color]"
      :disabled="!(data.State === 'registered' && !hasChanges) && button.Value === 'payout-export'"
      @click.prevent="onClick(button)"
    >
      {{ button.Title }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'field-state-buttons',
  props: {
    error: String,
    grid: Object,
    id: String,
    name: String,
    buttons: Array,
    data: Object,
    hasChanges: Boolean
  },
  computed: {},
  methods: {
    onClick(button) {
      switch (button.Type) {
        case 'file':
          if (!(button.Key in this.data)) return
          window.open(this.data[button.Key], '_blank')
          break
        case 'report':
          this.$router.push({
            name: 'do',
            params: {
              type: 'Report',
              actiontype: button.Value,
              trust: this.$route.params.trust,
              primaryKey: 'new'
            },
            query: {
              relationKey: button.Key,
              relationValue: this.data[button.ValueKey || button.Key]
            }
          })
          break
        default:
          if (!button.Value) return
          this.$router.push({ name: button.Value, params: { primaryKey: this.$route.params.primaryKey } })
      }
    }
  },
  components: {}
}
</script>

<style lang="scss" scoped>
.button-container {
  padding: 0 0 1rem 0;
}

.button {
  color: #fff;
  border-width: 0;
  padding: 1rem 1.5rem;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  margin-left: 0.5rem;
  white-space: nowrap;
  background: var(--blue-dark);

  &:hover {
    background: #3db1ec;
  }

  &[disabled] {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &:first-of-type {
    margin-left: 0;
  }
}
</style>
