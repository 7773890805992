<template>
  <div
    class="field field--select"
    :style="{
      '--form-item-width': grid.Cols,
      '--form-item-height': grid.Rows,
      '--form-item-start': grid.Offset,
      display: 'flex',
      justifyContent: 'flex-end'
    }"
  >
    <button :disabled="!hasChanges" class="button back-button" @click.prevent="$emit('refresh')" tabindex="-1">
      <font-awesome-icon :icon="['fa', 'step-backward']" /> Ångra
    </button>
    <button
      v-for="stateTransition in stateTransitions"
      :key="stateTransition.Value"
      :class="['button', stateTransition.Button.Color]"
      @click.prevent="onUpdate(stateTransition.Value)"
    >
      {{ stateTransition.Button.Title || stateTransition.Title }}
    </button>
    <button :disabled="!hasChanges" class="button save-button light-green" @click.prevent="$emit('save')" tabindex="-1">
      <font-awesome-icon :icon="['fa', 'save']" /> Spara
    </button>
  </div>
</template>

<script>
export default {
  name: 'field-state-buttons',
  props: {
    error: String,
    grid: Object,
    id: String,
    name: String,
    options: Array,
    states: Object,
    hasChanges: Boolean,
    value: [String, Boolean, Number],
    forcevalue: [String, Boolean, Number]
  },
  computed: {
    stateTransitions() {
      return (this.states?.[this.value] ?? []).sort((a, b) => (a.Title > b.Title ? 1 : -1))
    }
  },
  methods: {
    onUpdate(newValue) {
      this.$emit('input', newValue)
    }
  },
  components: {}
}
</script>

<style lang="scss" scoped>
.button-container {
  padding: 0 0 1rem 0;
}

.button {
  color: #fff;
  border-width: 0;
  padding: 1rem 1.5rem;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  margin-left: 0.5rem;
  background: var(--main-color);
  &:hover:not([disabled]) {
    background: var(--blue-dark);
  }

  &.red {
    background: #ec4444;
    &:hover:not([disabled]) {
      background: #ff6c6c;
    }
  }

  &.light-green {
    color: #000;
    background: var(--green-dark);
    &:hover:not([disabled]) {
      background: var(--green);
    }
  }

  &[disabled] {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &:first-of-type {
    margin-left: 0;
  }
}
</style>
