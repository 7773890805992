<template>
  <div class="main" v-if="singleProvider && fieldsProvider">
    <dynamicForm
      ref="form"
      :singleProvider="singleProvider"
      :fieldsProvider="fieldsProvider"
      :updateProvider="updateProvider"
      :createProvider="createProvider"
      :removeProvider="removeProvider"
      :actionProvider="actionProvider"
      :transmuteProvider="transmuteProvider"
      @created="onCreated($event)"
      @saved="onSaved($event)"
      @removed="onRemoved()"
      @execd="onExecd()"
      :fullHeight="true"
      :primaryKey="$route.params.primaryKey"
    />
  </div>
</template>

<script>
import dynamicForm from '@/components/dynamicForm'

export default {
  name: 'basregister-single',
  props: {
    iscommon: Boolean
  },
  shortcuts: {
    cancel() {
      this.gotoList()
    },
    newpage(e) {
      if (['INPUT', 'TEXTAREA', 'SELECT'].indexOf(e.target.nodeName) !== -1) {
        return
      }
      if (this.canNotLeave()) {
        return
      }
      this.gotoSingle('new')
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.canNotLeave()) {
      next(false)
    } else {
      next()
    }
  },
  methods: {
    canNotLeave() {
      return (
        this.$refs &&
        this.$refs.form.hasChanges &&
        !window.confirm('Du har osparade ändringar.\nÄr du säker på att du vill lämna?')
      )
    },
    gotoSingle(primaryKey) {
      this.$router.push({
        name: this.iscommon ? 'common-basregister-single' : 'basregister-single',
        params: {
          type: this.$route.params.type,
          trust: this.$route.params.trust,
          primaryKey: `${primaryKey}`
        }
      })
    },
    gotoList() {
      this.$router.push({
        name: this.iscommon ? 'common-basregister' : 'basregister',
        params: {
          type: this.$route.params.type,
          trust: this.$route.params.trust
        }
      })
    },
    onCreated(e) {
      if (this.$route.query.back) {
        this.$router.push(this.$route.query.back)
      } else {
        this.gotoSingle(e)
      }
    },
    onSaved() {
      if (!this.$route.query.back) {
        return
      }
      setTimeout(() => {
        this.$router.push(this.$route.query.back)
      }, 1000)
    },
    onRemoved() {
      if (this.$route.query.back) {
        this.$router.push(this.$route.query.back)
      } else {
        this.gotoList()
      }
    },
    onExecd() {},
    onPreviewd() {}
  },
  computed: {
    singleProvider() {
      if (this.apiClient === null) {
        return null
      }
      return this.apiClient.singleProvider(this.$route.params.type)
    },
    fieldsProvider() {
      if (this.apiClient === null) {
        return null
      }
      return this.apiClient.fieldsProvider(this.$route.params.type)
    },
    updateProvider() {
      if (this.apiClient === null) {
        return null
      }
      return this.apiClient.mutationProvider(this.$route.params.type, `update${this.$route.params.type}`)
    },
    createProvider() {
      if (this.apiClient === null) {
        return null
      }
      return this.apiClient.mutationProvider(this.$route.params.type, `create${this.$route.params.type}`)
    },
    removeProvider() {
      if (this.apiClient === null) {
        return null
      }
      return this.apiClient.mutationProvider(this.$route.params.type, `remove${this.$route.params.type}`)
    },
    actionProvider() {
      return (actiontype, pid) => {
        this.$router.push({
          name: this.iscommon ? 'common-do' : 'do',
          params: {
            type: this.$route.params.type,
            trust: this.$route.params.trust,
            actiontype: `${actiontype}`,
            primaryKey: pid
          }
        })
      }
    },
    transmuteProvider() {
      if (this.apiClient === null) {
        return null
      }
      return this.apiClient.mutationProvider(this.$route.params.type, `transmute${this.$route.params.type}`)
    },
    apiClient() {
      if (this.iscommon) {
        return this.$store.getters.api.common()
      } else {
        return this.$store.getters.api.trust(this.$route.params.trust)
      }
    }
  },
  components: {
    dynamicForm
  }
}
</script>
