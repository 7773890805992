<template>
  <div class="main" style="height: 100vh">
    <div v-if="timedout" style="width: 18rem; height: 6rem;transform: translate(-50%, -50%);position: relative;top: 50%;left: 50%;background-color: #01357c;color: white;">
      <div class="progress"></div>
      <h1 style="padding-left: 1rem;font-size: 17px;">Något har gått fel</h1>
      <h1 style="padding-left: 1rem;font-size: 13px;">Kontakta support</h1>
    </div>
    <div v-else style="width: 18rem; height: 6rem;transform: translate(-50%, -50%);position: relative;top: 50%;left: 50%;background-color: #01357c;color: white;">
      <div class="progress"></div>
      <h1 style="padding-left: 1rem;font-size: 17px;">Arbetar...</h1>
      <h1 v-if="takingToLong" style="padding-left: 1rem;font-size: 13px;">Det tar längre tid än vad det brukar</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'wait-for',
  props: {
    iscommon: Boolean
  },
  data () {
    return {
      started: 0,
      takingToLong: false,
      timedout: false
    }
  },
  computed: {
    singleProvider () {
      if (this.apiClient === null) {
        return null
      }
      return this.apiClient
        .singleProvider(this.$route.params.type)
    },
    apiClient () {
      if (this.iscommon) {
        return this.$store.getters.api.common()
      } else {
        return this.$store.getters.api.trust(this.$route.params.trust)
      }
    }
  },
  mounted () {
    this.started = Date.now()
    this.interval = setInterval(() => {
      const elapsedMs = Date.now() - this.started
      if (elapsedMs > 900000) {
        this.timedout = true
      } else if (elapsedMs > 30000) {
        this.takingToLong = true
      }
      const condParts = this.$route.params.condition.split(':')

      if (condParts[1] === 'direct') {
        this.$router.push({
          name: this.iscommon ? 'common-basregister-single' : 'basregister-single',
          params: {
            type: condParts[2].split('/')[0],
            trust: this.$route.params.trust,
            primaryKey: condParts[2].split('/')[1]
          }
        })
      }

      this.singleProvider({ filters: new Map([['ID', [this.$route.params.primaryKey]]]) })
        .then(resp => {
          let condMatch = false
          var condProp = null
          for (const key in resp.Data) {
            if (key === condParts[0] || key.toLowerCase() === condParts[0].toLowerCase()) {
              condProp = key
            }
          }
          if (condProp === null) {
            throw new Error('WaitFor condition key not found in response')
          }

          if (condParts[1] === 'not') {
            condMatch = resp.Data[condProp] !== condParts[2]
          } else {
            throw new Error('WaitFor condition not supported')
          }

          if (condMatch) {
            this.$router.push({
              name: this.iscommon ? 'common-basregister-single' : 'basregister-single',
              params: {
                type: this.$route.params.type,
                trust: this.$route.params.trust,
                primaryKey: `${resp.Data.ID}`
              }
            })
          }
        })
    }, 2000)
  },
  beforeDestroy () {
    clearInterval(this.interval)
  }
}
</script>

<style scoped lang="scss">
//@import url("//hello.myfonts.net/count/390b0b");
@font-face {
  font-family: 'Nexa';
  font-weight: 700;
  src: url('~@/assets/nexa/390B0B_0_0.eot');
  src:
    url('~@/assets/nexa/390B0B_0_0.eot?#iefix') format('embedded-opentype'),
    url('~@/assets/nexa/390B0B_0_0.woff2') format('woff2'),
    url('~@/assets/nexa/390B0B_0_0.woff') format('woff'),
    url('~@/assets/nexa/390B0B_0_0.ttf') format('truetype');
}
@font-face {
  font-family: 'Nexa';
  font-weight: 400;
  src: url('~@/assets/nexa/390B0B_1_0.eot');
  src:
    url('~@/assets/nexa/390B0B_1_0.eot?#iefix') format('embedded-opentype'),
    url('~@/assets/nexa/390B0B_1_0.woff2') format('woff2'),
    url('~@/assets/nexa/390B0B_1_0.woff') format('woff'),
    url('~@/assets/nexa/390B0B_1_0.ttf') format('truetype');
}

@import "~bulma/sass/utilities/_all";

// Set variables to overwrite Buefy
$family-sans-serif: 'Arial', sans-serif;

// Set your colors
$deep-sapphire: #012169;
$lochmara: #0085CA;
$calypso: #34657F;
$silver-chalice: #A7A8AA;

$primary: $deep-sapphire;
$primary-invert: findColorInvert($primary);
$info: $lochmara;
$info-invert: findColorInvert($info);

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
    "white": ($white, $black),
    "black": ($black, $white),
    "light": ($light, $light-invert),
    "dark": ($dark, $dark-invert),
    "primary": ($primary, $primary-invert),
    "info": ($info, $info-invert),
    "success": ($success, $success-invert),
    "warning": ($warning, $warning-invert),
    "danger": ($danger, $danger-invert)
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

@import "~bulma";
//@import "~buefy/src/scss/buefy";

* {
  font-family: $family-sans-serif;
}

h1,h2,h3,h4,h5,h6,.title {
  font-family: "Nexa", sans-serif;
  font-weight: 700;
}

a:hover, a:active, a:focus {
  outline: 0;
}
.navbar-item::-moz-focus-inner {
  border: 0;
}
.has-light-border-top {
  border-top: 1px solid $grey-lighter;
}
.table {
  thead {
    td, th {
      &, & > * {
        font-family: "Nexa", sans-serif;
      }
    }
  }
  &.is-unbordered {
    td, th {
      border-width: 0;
    }
  }
  &.is-aligned-middle {
    td, th {
      vertical-align: middle;
    }
  }
}
  $deep-sapphire: #012169;
  $lochmara: #0085CA;
  .is-bankid-field {
    position: relative;
    label.label {
      position: absolute;
      top: 23px;
      left: 14px;
      z-index: 90;
      font-size: 1.2rem;
      margin: 0;
      pointer-events: none;
      transform-origin: top left;
      transition: transform 150ms ease;
      font-weight: 400;
      padding: 0;
    }
    &.has-input {
      label {
         transform: scale(0.65) translateY(-15px);
      }
    }
    input {
      padding: 1.6rem 0.8rem 0.8rem 0.8rem;
      height: auto;
      font-size: 1.2rem;
      &::-ms-clear {
        display: none;
      }
    }
    .control .icon {
      width: 2.85em;
      height: 100%;
      &.is-right {
        right: 5px;
      }
    }
  }
  .icon-bankid {
    height: 1.5rem;
  }

  .button.is-fullwidth {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    height: auto;
  }

  .progress {
    height: 1rem;
    width: 100%;
    background: #333;
    border-radius: 0px;
    background: radial-gradient(circle at center, $lochmara 0, $deep-sapphire 100%) repeat center center;
    margin-bottom: 0px;
    animation: progress 5s linear infinite;
    @media screen and (max-width: 768px) {
      animation: progress-full-width 5s linear infinite;
    }
  }
  .info-box {
    padding: 3rem 0;
    background: #f6fcff;
    .bankid-text {
      color: $deep-sapphire;
      display: flex;
      align-items: center;
      font-weight: 800;
      font-size: 0.9rem;
      span {
        font-family: "Nexa", sans-serif;
      }
      .icon {
        margin-right: 0.7rem;
        width: 2em;
        height: 2em;
      }
    }
  }
  @keyframes progress {
    from { background-position: 0 0; }
    to { background-position: 50vw 0; }
  }
  @keyframes progress-full-width {
    from { background-position: 0 0; }
    to { background-position: 100vw 0; }
  }
</style>
