<template>
  <div class="main">
    <div class="form">
      <div class="fieldset">
        <fieldInput
          v-model="queryString">Sök</fieldInput>
      </div>
      <template v-if="providers && queryString.length >= 2">
        <template v-for="provider in providers">
          <div :key="provider.key" class="fieldset" :style="{display: rowCount[provider.key] > 0 ? '' : 'none'}">
            <h2 v-if="provider.label" class="fieldset__title">{{ provider.label }}</h2>
            <div class="field field--text" :style="{ '--form-item-width': 3 }">
              <dynamicTable ref="searchTable"
                :fullHeight="false"
                :filtering="false"
                :listProvider="provider.list"
                :fieldsProvider="provider.fields"
                :onRowClick="(e) => { gotoSingle(provider, e) }"
                :showCreateButton="false"
                @rows-updated="updateRowCount(provider.key, $event)" />
            </div>
          </div>
        </template>
        <!-- <h1 v-else>Gör en sökning</h1> -->
      </template>
    </div>
  </div>
</template>

<script>
import fieldInput from '@/components/form-fields/fieldInput'
import dynamicTable from '@/components/dynamicTable'

export default {
  name: 'sok',
  data () {
    return {
      trusts: [],
      queryString: '',
      rowCount: {}
    }
  },
  shortcuts: {
    cancel () {
      this.$router.push({
        name: 'home'
      })
    },
    newpage (e) {
      if (['INPUT', 'TEXTAREA', 'SELECT'].indexOf(e.target.nodeName) !== -1) {
        return
      }
      this.gotoSingle('new')
    }
  },
  methods: {
    gotoSingle (provider, primaryKey) {
      const route = {
        name: provider.iscommon ? 'common-basregister-single' : 'basregister-single',
        params: {
          type: provider.type,
          trust: !provider.iscommon && provider.trust ? provider.trust.Slug : this.$route.params.trust,
          primaryKey: `${primaryKey}`
        }
      }
      this.$router.push(route)
    },
    searchProvider (listProvider) {
      return (query) => {
        query.filters = query.filters || new Map()

        query.search = this.queryString

        return listProvider(query)
      }
    },
    updateRowCount (key, rows) {
      this.$set(this.rowCount, key, rows ? rows.length : 0)
    }
  },
  computed: {
    providers () {
      if (this.$store.getters.api === null) {
        return null
      }
      // this.$store.getters.api.trust(this.$route.params.trust)
      var prov = [
        {
          key: 'Advisor',
          label: 'Rådgivare',
          type: 'Advisor',
          iscommon: true,
          fields: this.$store.getters.api.common().fieldsProvider('Advisor'),
          list: this.searchProvider(this.$store.getters.api.common().listProvider('Advisor'))
        }
      ]

      for (let i = 0; i < this.trusts.length; i++) {
        const trust = this.trusts[i]
        const p = [
          {
            key: `${trust.ID}_Employer`,
            label: `${trust.Name} - Arbetsgivare`,
            type: 'Employer',
            trust: trust,
            fields: this.$store.getters.api.trust(trust.Slug).fieldsProvider('Employer'),
            list: this.searchProvider(this.$store.getters.api.trust(trust.Slug).listProvider('Employer'))
          },
          {
            key: `${trust.ID}_Pensionable`,
            label: `${trust.Name} - Pensionsberättigade`,
            type: 'Pensionable',
            trust: trust,
            fields: this.$store.getters.api.trust(trust.Slug).fieldsProvider('Pensionable'),
            list: this.searchProvider(this.$store.getters.api.trust(trust.Slug).listProvider('Pensionable'))
          }
        ]
        prov = [...prov, ...p]
      }
      return prov
    }
  },
  mounted () {
    this.$store.getters.api.common().trusts()
      .then(resp => {
        this.trusts = resp.data.trusts.filter(t => !t.Fake)
        if (this.$route.query.q) this.queryString = this.$route.query.q
      })
  },
  watch: {
    queryString (str) {
      if (this.$route.query.q !== str) this.$router.replace({ query: { q: str } })
    }
  },
  components: {
    dynamicTable,
    fieldInput
  }
}
</script>
