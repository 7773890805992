import Observer from './Observer'
import Emitter from './Emitter'

export default {
  install (Vue, options) {
    if (!options) {
      options = {}
    }
    const observer = new Observer(options)
    Vue.prototype.$shortcut = observer

    Vue.mixin({
      created () {
        // save current methods
        const shortcuts = this.$options.shortcuts

        this.$options.shortcuts = new Proxy(
          {},
          {
            set: (target, key, value) => {
              Emitter.addListener(key, value, this)
              target[key] = value
              return true
            },
            deleteProperty: (target, key) => {
              Emitter.removeListener(key, this.$options.shortcuts[key], this)
              delete target.key
              return true
            }
          }
        )

        if (shortcuts) {
          // restore current methods
          Object.keys(shortcuts).forEach(key => {
            this.$options.shortcuts[key] = shortcuts[key]
          })
        }
      },
      beforeDestroy () {
        const shortcuts = this.$options.shortcuts

        if (shortcuts) {
          Object.keys(shortcuts).forEach(key => {
            delete this.$options.shortcuts[key]
          })
        }
      }
    })
  }
}
