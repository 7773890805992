<template>
  <div
    class="field field--select"
    :style="{ '--form-item-width': grid.Cols, '--form-item-height': grid.Rows, '--form-item-start': grid.Offset }"
  >
    <label :for="id" class="field__label">
      <slot></slot>
    </label>
    <ul>
      <li v-for="item in value" :key="item">
        <span class="text">{{ item }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'field-array-table',
  props: {
    error: String,
    grid: Object,
    id: String,
    name: String,
    options: Array,
    states: Object,
    fields: Array,
    value: Array,
    forcevalue: Array
  },
  computed: {},
  methods: {
    amountOfDecimals(value) {
      if (!value) {
        return 0
      }
      return (
        Number(value)
          .toString()
          .split('.')[1] || ''
      ).length
    }
  },
  components: {}
}
</script>

<style lang="scss" scoped>
.text {
  display: inline-block;
  // color: #fff;
  background-color: var(--green);
  border-radius: 1em;
  border: 0;
  // padding: 0.5rem;
  padding: 0.5em 0.75em;
  // margin-bottom: 1rem;
}
</style>
