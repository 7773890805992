<template>
  <div
    class="columns relative columns--full-height columns--vertical-center columns--no-vertical-margin columns--no-horizontal-margin"
  >
    <div class="column is-6 column--no-horizontal-padding">
      <section class="login">
        <div class="columns">
          <div class="column is-8 is-offset-2 is-10-mobile is-offset-1-mobile">
            <h1 class="title is-size-3">{{ title }}</h1>
          </div>
        </div>
        <div v-if="isLoggedIn" class="columns">
          <div class="column is-8 is-offset-2 is-10-mobile is-offset-1-mobile">
            <slot></slot>
          </div>
        </div>
        <slot v-else></slot>
        <div class="columns">
          <div class="column is-8 is-offset-2 is-10-mobile is-offset-1-mobile">
            <footer class="login__footer has-text-centered">
              <img src="@/assets/images/stifta.svg" />
            </footer>
          </div>
        </div>
      </section>
    </div>
    <div
      class="column column--full-height column--background-image is-6 is-hidden-mobile column--no-horizontal-padding"
    >
      <div class="background-image background-image--full"></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'login-container',
  props: {
    title: String
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'isLoggedIn'
    })
  }
}
</script>

<style scoped lang="scss">
//@import url("//hello.myfonts.net/count/390b0b");
@font-face {
  font-family: 'Nexa';
  font-weight: 700;
  src: url('~@/assets/nexa/390B0B_0_0.eot');
  src: url('~@/assets/nexa/390B0B_0_0.eot?#iefix') format('embedded-opentype'),
    url('~@/assets/nexa/390B0B_0_0.woff2') format('woff2'), url('~@/assets/nexa/390B0B_0_0.woff') format('woff'),
    url('~@/assets/nexa/390B0B_0_0.ttf') format('truetype');
}
@font-face {
  font-family: 'Nexa';
  font-weight: 400;
  src: url('~@/assets/nexa/390B0B_1_0.eot');
  src: url('~@/assets/nexa/390B0B_1_0.eot?#iefix') format('embedded-opentype'),
    url('~@/assets/nexa/390B0B_1_0.woff2') format('woff2'), url('~@/assets/nexa/390B0B_1_0.woff') format('woff'),
    url('~@/assets/nexa/390B0B_1_0.ttf') format('truetype');
}

@import '~bulma/sass/utilities/_all';

// Set variables to overwrite Buefy
$family-sans-serif: 'Arial', sans-serif;

// Set your colors
$deep-sapphire: #012169;
$lochmara: #0085ca;
$calypso: #34657f;
$silver-chalice: #a7a8aa;

$primary: $deep-sapphire;
$primary-invert: findColorInvert($primary);
$info: $lochmara;
$info-invert: findColorInvert($info);

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
  'white': (
    $white,
    $black
  ),
  'black': (
    $black,
    $white
  ),
  'light': (
    $light,
    $light-invert
  ),
  'dark': (
    $dark,
    $dark-invert
  ),
  'primary': (
    $primary,
    $primary-invert
  ),
  'info': (
    $info,
    $info-invert
  ),
  'success': (
    $success,
    $success-invert
  ),
  'warning': (
    $warning,
    $warning-invert
  ),
  'danger': (
    $danger,
    $danger-invert
  )
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

@import '~bulma';
//@import "~buefy/src/scss/buefy";

* {
  font-family: $family-sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
.title {
  font-family: 'Nexa', sans-serif;
  font-weight: 700;
}

a:hover,
a:active,
a:focus {
  outline: 0;
}
.navbar-item::-moz-focus-inner {
  border: 0;
}
.has-light-border-top {
  border-top: 1px solid $grey-lighter;
}
.table {
  thead {
    td,
    th {
      &,
      & > * {
        font-family: 'Nexa', sans-serif;
      }
    }
  }
  &.is-unbordered {
    td,
    th {
      border-width: 0;
    }
  }
  &.is-aligned-middle {
    td,
    th {
      vertical-align: middle;
    }
  }
}
$deep-sapphire: #012169;

body {
  min-height: 100vh;
}
.columns--full-height,
.column--full-height {
  height: 100vh;
}
.columns--vertical-center {
  align-items: center;
}
.columns--no-vertical-margin {
  margin-bottom: 0;
  margin-top: 0;
  &:not(:last-child) {
    margin-bottom: 0;
  }
}
.columns--no-horizontal-margin {
  margin-left: 0;
  margin-right: 0;
}
.column--no-horizontal-padding {
  padding-left: 0;
  padding-right: 0;
}
.column--background-image {
  overflow: hidden;
}
.background-image {
  background: $deep-sapphire url('../assets/images/login-background.jpg') no-repeat center center / cover;
  transition: filter 200ms linear;
}
.background-image--full {
  width: 120%;
  height: 120%;
  margin-top: -10%;
  margin-left: -10%;
}
.has-blur {
  filter: blur(5px);
}
.login__footer {
  padding-top: 20px;
  img {
    max-height: 1.75rem;
  }
}
.hand {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 50vw;
  transform: translateX(100%);
  transition: all 300ms ease;
  &.in-view {
    transform: translateX(0%);
  }
  &.is-faded {
    opacity: 0;
  }
}
</style>
