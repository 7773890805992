<template>
  <div class="main" v-if="fieldsProvider">
    <dynamicForm
      :fieldsProvider="fieldsProvider"
      :previewProvider="previewProvider"
      :execProvider="execProvider"
      @execd="onExecd($event)"
      :fullHeight="true"
    />
  </div>
</template>

<script>
import dynamicForm from '@/components/dynamicForm'

export default {
  name: 'do-action',
  props: {
    iscommon: Boolean
  },
  computed: {
    actionEntity() {
      return `${this.$route.params.type}_${this.$route.params.actiontype}`
    },
    fieldsProvider() {
      return this.apiClient.fieldsProvider(this.actionEntity)
    },
    previewProvider() {
      return this.apiClient.mutationProvider(this.actionEntity, `preview${this.actionEntity}`)
    },
    execProvider() {
      return this.apiClient.mutationProvider(this.actionEntity, `exec${this.actionEntity}`)
    },
    apiClient() {
      if (this.iscommon) {
        return this.$store.getters.api.common()
      } else {
        return this.$store.getters.api.trust(this.$route.params.trust)
      }
    }
  },
  methods: {
    onExecd(e) {
      if (!e.WaitFor || (!e.ID && e.WaitFor.indexOf('_:direct') !== 0)) {
        return
      }
      this.$router.push({
        name: this.iscommon ? 'common-wait-for' : 'wait-for',
        params: {
          type: this.$route.params.type,
          trust: this.$route.params.trust,
          primaryKey: `${e.ID || '_'}`,
          condition: e.WaitFor
        }
      })
    }
  },
  components: {
    dynamicForm
  }
}
</script>
