import TrustApi from '@/api/TrustApi'
import CommonApi from '@/api/CommonApi'

export default class ProfileApi {
  constructor (token) {
    this.token = token
    this._clients = new Map()
  }

  /**
   * TrustAPI
   */
  trust (trustSlug) {
    let client = this._clients.get(`trust.${trustSlug}`)
    if (client === undefined) {
      client = new TrustApi(trustSlug, this.token)
      this._clients.set(`trust.${trustSlug}`, client)
    }

    return client
  }

  /**
   * CommonAPI
   */
  common () {
    let client = this._clients.get('common')
    if (client === undefined) {
      client = new CommonApi(this.token)
      this._clients.set('common', client)
    }

    return client
  }

  checkToken () {
    return new Promise((resolve, reject) => {
      this.common().trusts()
        .then(() => {
          resolve(true)
        })
        .catch(err => {
          if (err.response && err.response.status === 401) {
            resolve(false)
            return
          }
          reject(err)
        })
    })
  }
}
