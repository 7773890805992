<template>
  <div
    class="field field--textarea"
    :style="{ '--form-item-width': grid.Cols, '--form-item-height': grid.Rows, '--form-item-start': grid.Offset }"
  >
    <label :for="id" class="field__label">
      <slot></slot>
    </label>
    <textarea
      v-if="!disabled"
      :value="value"
      :id="id"
      :name="name"
      :disabled="disabled"
      :placeholder="placeholder"
      :readonly="readonly"
      :rows="textrows"
      @input="update($event.target.value)"
      @keydown.enter.prevent="$emit('nextInput')"
    ></textarea>
    <pre v-else>{{ value }}</pre>
    <small v-if="error" style="color: red; font-style: italic">{{ error }}</small>
  </div>
</template>

<script>
export default {
  name: 'field-textarea',
  props: {
    error: String,
    grid: Object,
    id: String,
    name: String,
    placeholder: String,
    disabled: Boolean,
    readonly: Boolean,
    textrows: Number,
    value: String
  },
  methods: {
    update(newValue) {
      this.$emit('input', newValue)
    }
  }
}
</script>
