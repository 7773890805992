<template>
  <div
    :class="{
      container: true,
      'banner-visible': bannerVisible,
      'common-notice-visible': $route.meta.showCommonNotice
    }"
    id="app"
  >
    <div class="env-banner">Produktionsmiljö</div>
    <div class="common-notice" title="Ändringar som görs här påverkar alla stiftelser">
      <font-awesome-icon :icon="['fal', 'exclamation-triangle']" />
      Detta är ett gemensamt basregister
    </div>
    <div class="navigation" v-if="!$route.meta.hideNavigation">
      <div>
        <router-link class="navigation__logo-link" :to="{ name: 'home' }" tabindex="-1">
          <img src="@/assets/images/S.svg" alt="Stifta logotyp" class="logo" />
          <span class="navigation__title">{{ $store.getters.currentTrustName }}</span>
        </router-link>
        <nav>
          <div class="navigation__item-container">
            <router-link
              class="navigation__item"
              tabindex="-1"
              :to="{ name: 'basregister', params: { type: 'Employer', trust: $route.params.trust } }"
            >
              <span class="navigation__icon">
                <font-awesome-icon :icon="['fal', 'cabinet-filing']" fixed-width />
              </span>
              <span class="navigation__label">Basregister</span>
            </router-link>
            <div class="navigation__submenu">
              <router-link
                class="navigation__subitem"
                tabindex="-1"
                :to="{ name: 'basregister', params: { type: 'Employer', trust: $route.params.trust } }"
                >Arbetsgivare</router-link
              >
              <router-link
                class="navigation__subitem"
                tabindex="-1"
                :to="{ name: 'basregister', params: { type: 'Pensionable', trust: $route.params.trust } }"
                >Pensionsberättigade</router-link
              >
              <router-link
                class="navigation__subitem"
                tabindex="-1"
                :to="{ name: 'common-basregister', params: { type: 'Advisor', trust: $route.params.trust } }"
                >Rådgivare</router-link
              >
              <router-link
                class="navigation__subitem"
                tabindex="-1"
                :to="{ name: 'common-basregister', params: { type: 'Account', trust: $route.params.trust } }"
                >Kontoplan</router-link
              >
              <router-link
                class="navigation__subitem"
                tabindex="-1"
                :to="{ name: 'basregister', params: { type: 'Pledge', trust: $route.params.trust } }"
                >Utfästelser</router-link
              >
              <router-link
                class="navigation__subitem"
                tabindex="-1"
                :to="{ name: 'common-basregister', params: { type: 'TransactionType', trust: $route.params.trust } }"
                >Transaktionstyp</router-link
              >
              <router-link
                class="navigation__subitem"
                tabindex="-1"
                :to="{ name: 'basregister', params: { type: 'Instrument', trust: $route.params.trust } }"
                >Placeringar</router-link
              >
            </div>
          </div>
          <div class="navigation__item-container">
            <router-link
              class="navigation__item"
              tabindex="-1"
              :to="{
                name: 'do',
                params: { type: 'Transaction', actiontype: 'deposit', trust: $route.params.trust, primaryKey: 'new' }
              }"
            >
              <span class="navigation__icon">
                <font-awesome-icon :icon="['fal', 'handshake-alt']" fixed-width />
              </span>
              <span class="navigation__label">Registrering</span>
            </router-link>
            <div class="navigation__submenu">
              <router-link
                class="navigation__subitem"
                tabindex="-1"
                :to="{
                  name: 'do',
                  params: { type: 'Transaction', actiontype: 'deposit', trust: $route.params.trust, primaryKey: 'new' }
                }"
                >Registrering</router-link
              >
              <router-link
                class="navigation__subitem"
                tabindex="-1"
                :to="{
                  name: 'do',
                  params: { type: 'Transaction', actiontype: 'growth', trust: $route.params.trust, primaryKey: 'new' }
                }"
                >Ränteuppdatering</router-link
              >
              <router-link
                class="navigation__subitem"
                tabindex="-1"
                :to="{
                  name: 'do',
                  params: { type: 'Transaction', actiontype: 'fee', trust: $route.params.trust, primaryKey: 'new' }
                }"
                >Avgifter</router-link
              >
              <router-link
                class="navigation__subitem"
                tabindex="-1"
                :to="{ name: 'basregister', params: { type: 'Transaction', trust: $route.params.trust } }"
                >Transaktioner</router-link
              >
              <hr style="color: rgba(0,0,0,.2);margin-left: 32px;margin-top: 3px;margin-bottom: 3px;" />

              <router-link
                class="navigation__subitem"
                tabindex="-1"
                :to="{
                  name: 'do',
                  params: { type: 'Payout', actiontype: 'salary', trust: $route.params.trust, primaryKey: 'new' }
                }"
                >Pensionsutbetalning</router-link
              >
              <router-link
                class="navigation__subitem"
                tabindex="-1"
                :to="{ name: 'basregister', params: { type: 'Payout', trust: $route.params.trust } }"
                >Utbetalningar</router-link
              >
              <router-link
                class="navigation__subitem"
                tabindex="-1"
                :to="{ name: 'basregister', params: { type: 'PayoutRow', trust: $route.params.trust } }"
                >Utbetalningsrader</router-link
              >
              <hr style="color: rgba(0,0,0,.2);margin-left: 32px;margin-top: 3px;margin-bottom: 3px;" />

              <router-link
                class="navigation__subitem"
                tabindex="-1"
                :to="{ name: 'basregister', params: { type: 'Execution', trust: $route.params.trust } }"
                >Historik</router-link
              >
              <router-link
                class="navigation__subitem"
                tabindex="-1"
                :to="{
                  name: 'do',
                  params: { type: 'Transaction', actiontype: 'history', trust: $route.params.trust, primaryKey: 'new' }
                }"
                >Historiskt Kapital</router-link
              >
            </div>
          </div>
          <div class="navigation__item-container">
            <router-link
              class="navigation__item"
              tabindex="-1"
              :to="{ name: 'basregister', params: { type: 'Report', trust: $route.params.trust } }"
            >
              <span class="navigation__icon">
                <font-awesome-icon :icon="['fal', 'file-chart-pie']" fixed-width />
              </span>
              <span class="navigation__label">Rapporter</span>
            </router-link>
            <div class="navigation__submenu">
              <router-link
                class="navigation__subitem"
                tabindex="-1"
                :to="{
                  name: 'do',
                  params: { type: 'Report', actiontype: 'bookkeeping', trust: $route.params.trust, primaryKey: 'new' }
                }"
                >Bokföringsunderlag</router-link
              >
              <router-link
                class="navigation__subitem"
                tabindex="-1"
                :to="{
                  name: 'do',
                  params: { type: 'Report', actiontype: 'typereport', trust: $route.params.trust, primaryKey: 'new' }
                }"
                >Typrapport</router-link
              >
              <router-link
                class="navigation__subitem"
                tabindex="-1"
                :to="{
                  name: 'do',
                  params: {
                    type: 'Report',
                    actiontype: 'transactionspensionable',
                    trust: $route.params.trust,
                    primaryKey: 'new'
                  }
                }"
                >Destinatärsrapport</router-link
              >

              <router-link
                class="navigation__subitem"
                tabindex="-1"
                :to="{
                  name: 'do',
                  params: {
                    type: 'Report',
                    actiontype: 'valuestatement',
                    trust: $route.params.trust,
                    primaryKey: 'new'
                  }
                }"
                >Värdebesked</router-link
              >

              <!-- <router-link
                class="navigation__subitem"
                tabindex="-1"
                :to="{
                  name: 'do',
                  params: {
                    type: 'Report',
                    actiontype: 'payoutsignallist',
                    trust: $route.params.trust,
                    primaryKey: 'new'
                  }
                }"
                >Signallista</router-link
              > -->

              <!-- <router-link
                class="navigation__subitem"
                tabindex="-1"
                :to="{
                  name: 'do',
                  params: {
                    type: 'Report',
                    actiontype: 'payoutchecklist',
                    trust: $route.params.trust,
                    primaryKey: 'new'
                  }
                }"
                >Kontrolllista</router-link
              > -->

              <router-link
                class="navigation__subitem"
                tabindex="-1"
                :to="{
                  name: 'do',
                  params: {
                    type: 'Report',
                    actiontype: 'pledgestatement',
                    trust: $route.params.trust,
                    primaryKey: 'new'
                  }
                }"
                >Utfästelserapport</router-link
              >

              <!-- <router-link class="navigation__subitem" tabindex="-1" :to="{name: 'do', params: {type: 'Report', actiontype: 'bookkeepingbasis', trust: $route.params.trust, primaryKey: 'new'}}">Bokföringsunderlag</router-link> -->
              <router-link
                class="navigation__subitem"
                tabindex="-1"
                :to="{
                  name: 'do',
                  params: { type: 'Report', actiontype: 'totals', trust: $route.params.trust, primaryKey: 'new' }
                }"
                >Totaler</router-link
              >
              <!-- <router-link class="navigation__subitem" tabindex="-1" :to="{name: 'do', params: {type: 'Report', actiontype: 'ValueNotificationReport', trust: $route.params.trust}}">Värdebesked</router-link>
              <router-link class="navigation__subitem" tabindex="-1" :to="{name: 'do', params: {type: 'Report', actiontype: 'PensionNotificationReport', trust: $route.params.trust}}">Pensionsbesked</router-link> -->
              <router-link
                class="navigation__subitem"
                tabindex="-1"
                :to="{ name: 'basregister', params: { type: 'Report', trust: $route.params.trust } }"
                >Historik</router-link
              >
            </div>
          </div>
          <div class="navigation__item-container">
            <router-link
              class="navigation__item"
              tabindex="-1"
              :to="{ name: 'basregister', params: { type: 'Settings', trust: $route.params.trust } }"
            >
              <span class="navigation__icon">
                <font-awesome-icon :icon="['fal', 'sliders-h-square']" fixed-width />
              </span>
              <span class="navigation__label">Inställningar</span>
            </router-link>
            <div class="navigation__submenu">
              <router-link
                class="navigation__subitem"
                tabindex="-1"
                :to="{ name: 'basregister', params: { type: 'Settings', trust: $route.params.trust } }"
                >Allmänna</router-link
              >
              <router-link
                class="navigation__subitem"
                tabindex="-1"
                :to="{ name: 'basregister', params: { type: 'Fee', trust: $route.params.trust } }"
                >Avgifter</router-link
              >
              <router-link
                class="navigation__subitem"
                tabindex="-1"
                :to="{ name: 'common-basregister', params: { type: 'Trust', trust: $route.params.trust } }"
                >Stiftelser</router-link
              >
              <router-link
                class="navigation__subitem"
                tabindex="-1"
                :to="{ name: 'common-basregister', params: { type: 'Auditlog', trust: $route.params.trust } }"
                >Revisionslogg</router-link
              >
              <router-link
                class="navigation__subitem"
                tabindex="-1"
                :to="{ name: 'common-basregister', params: { type: 'Prisbasbelopp', trust: $route.params.trust } }"
                >Prisbasbelopp</router-link
              >
            </div>
          </div>
          <div class="navigation__item-container">
            <router-link
              class="navigation__item"
              tabindex="-1"
              :to="{ name: 'sok', params: { trust: $route.params.trust } }"
            >
              <span class="navigation__icon">
                <font-awesome-icon :icon="['fal', 'search']" fixed-width />
              </span>
              <span class="navigation__label">Sök</span>
            </router-link>
          </div>
          <div class="navigation__item-container">
            <a class="navigation__item" v-on:click="logout()" tabindex="-1">
              <span class="navigation__icon">
                <font-awesome-icon :icon="['fal', 'sign-out']" flip="horizontal" fixed-width />
              </span>
              <span class="navigation__label">Logga ut</span>
            </a>
          </div>
        </nav>
      </div>
    </div>
    <router-view />
    <div v-if="$store.getters.errorMessage !== null" class="crash-overlay">
      <div>
        <h1>{{ $store.getters.errorMessage }}</h1>
        <p>Var god ladda om sidan</p>
      </div>
    </div>
    <reAuthModal />
  </div>
</template>

<style src="@/assets/style.css"></style>

<script>
import Vue from 'vue'
import reAuthModal from '@/components/auth/reAuthModal'

export default Vue.extend({
  name: 'app',
  methods: {
    logout() {
      this.$store.dispatch('logout')
      this.$router.push({ name: 'auth' })
    }
  },
  computed: {
    bannerVisible() {
      // Production environment doesn't have a node_env set
      return process.env.VUE_APP_ENV === 'production'
    }
  },
  components: { reAuthModal }
})
</script>

<style lang="scss" scoped>
.crash-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99990;
  background-color: rgba(0, 0, 0, 0.59);
  h1 {
    font-size: 30px;
  }
  & > div {
    color: white;
    background-color: #01086f;
    border-radius: 10px;
    padding: 1rem;
    margin: auto;
    width: 25rem;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.env-banner {
  display: none;
}

// Production banner to make it clear that this is live data
.banner-visible {
  .env-banner {
    display: block;
  }

  .env-banner {
    position: fixed;
    top: 0;
    left: 0;
    height: 2rem;
    width: 100%;
    z-index: 99999;
    background-color: #ff6c6c;
    color: white;
    font-size: 1rem;
    text-align: center;
    line-height: 2rem;
  }
}

// Common register banner to make it clear that this is a common register
.common-notice {
  display: none;
}

.common-notice-visible {
  .common-notice {
    display: block;
  }

  .common-notice {
    position: fixed;
    top: 0;
    left: 0;
    height: 2rem;
    width: 100%;
    z-index: 99999;
    background-color: #6ca7ff;
    color: white;
    font-size: 1rem;
    text-align: center;
    line-height: 2rem;
  }
}

// Banner offsets
.banner-visible,
.common-notice-visible {
  &.container {
    margin-top: 2rem;
  }

  ::v-deep .button-container,
  ::v-deep .message-container {
    margin-top: 2rem !important;
  }
  &#app {
    min-height: calc(100vh - 2rem);
  }
  ::v-deep .main > div {
    max-height: calc(100vh - 2rem);
  }
}

.banner-visible.common-notice-visible {
  &.container {
    margin-top: 4rem;
  }
  .common-notice {
    top: 2rem;
  }
  ::v-deep .button-container,
  ::v-deep .message-container {
    margin-top: 4rem !important;
  }
  &#app {
    min-height: calc(100vh - 4rem);
  }
  ::v-deep .main > div {
    max-height: calc(100vh - 4rem);
  }
}
</style>
