<template>
  <loginContainer title="Logga in">
    <template v-if="!passwordAuth">
      <bankIdAuth />
      <div class="columns">
        <button
          v-on:click="passwordAuth = true"
          class="switch-method column is-8 is-offset-2 is-10-mobile is-offset-1-mobile"
        >
          Logga in med lösenord
        </button>
      </div>
    </template>
    <template v-else>
      <passwordAuth />
      <div class="columns">
        <button
          v-on:click="passwordAuth = false"
          class="switch-method column is-8 is-offset-2 is-10-mobile is-offset-1-mobile"
        >
          Logga in med BankID
        </button>
      </div>
    </template>
  </loginContainer>
</template>

<script>
import bankIdAuth from '@/components/auth/bankIdAuth'
import passwordAuth from '@/components/auth/passwordAuth'
import loginContainer from '@/components/loginContainer'

export default {
  name: 'auth',
  data() {
    return {
      passwordAuth: false
    }
  },
  components: {
    bankIdAuth,
    passwordAuth,
    loginContainer
  }
}
</script>

<style scoped>
.switch-method {
  background: none;
  border: none;
  cursor: pointer;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  color: #888;
  text-decoration: underline;
  text-align: left;
}
</style>
