<template>
  <div>
    <div class="message-container" v-if="loading || fault || warning">
      <span style="font-size: 20px" v-if="loading">Laddar...</span>
      <br v-if="loading && (warning || fault)" />
      <span style="font-size: 20px; color: rgb(255, 225, 108)" v-if="warning">{{ warning }}</span>
      <span style="font-size: 20px; color: rgb(255, 108, 108)" v-if="fault">{{ fault }}</span>
    </div>
    <div class="button-container" v-if="primaryKey === 'new'">
      <router-link v-if="backUrl" :to="backUrl" tabindex="-1">Avbryt</router-link>
      <button v-if="createProvider" ref="createButton" class="button save-button" @click="create()" tabindex="-1">
        Skapa (ctrl+s)
      </button>
    </div>
    <div class="button-container" v-else>
      <router-link v-if="backUrl" :to="backUrl" tabindex="-1">Tillbaka</router-link>
      <template>
        <button
          v-for="link in links"
          v-bind:key="link.Title + link.Key"
          class="button action-button"
          @click="doLink(link)"
        >
          {{ link.Title }}
        </button>
      </template>
      <template v-if="actionProvider && $route.params.type !== 'Payout'">
        <button
          v-for="action in actions"
          v-bind:key="action.Action"
          class="button action-button"
          @click="doAction(action)"
        >
          {{ action.Name }}
        </button>
      </template>
      <template v-if="$route.params.type !== 'Payout'">
        <button
          v-if="removeProvider && $route.params.type !== 'Transaction'"
          class="button remove-button"
          @click="remove()"
          tabindex="-1"
        >
          <font-awesome-icon :icon="['fa', 'trash']" /> Ta bort
        </button>
        <button v-else-if="revertProvider" class="button remove-button" @click="edit()" tabindex="-1">
          <font-awesome-icon :icon="['fa', 'edit']" /> Ångra
        </button>
        <button
          v-if="singleProvider"
          :disabled="!hasChanges"
          class="button back-button"
          @click="refresh()"
          tabindex="-1"
        >
          <font-awesome-icon :icon="['fa', 'step-backward']" /> Avbryt
        </button>
        <button
          v-if="updateProvider"
          ref="saveButton"
          :disabled="!hasChanges"
          class="button save-button"
          @click="save()"
          tabindex="-1"
        >
          <font-awesome-icon :icon="['fa', 'save']" /> Spara (ctrl+s)
        </button>
      </template>
      <button v-if="execProvider" ref="execButton" class="button save-button" @click="exec()" tabindex="-1">
        <template v-if="['Report', 'Payout'].includes($route.params.type)">
          Skapa (ctrl+s)
        </template>
        <template v-else>
          Registrera (ctrl+s)
        </template>
      </button>
    </div>
    <form class="form" v-if="editData != null" :style="fullHeight ? 'min-height: 100vh' : ''" ref="formRef">
      <div
        class="fieldset"
        :class="{
          fieldset: true,
          'is-inline-block': group.Inline
        }"
        v-for="(group, i) in formGroups"
        :key="i"
        :style="`--form-section-width:${group.Cols || 4}`"
      >
        <h2 class="fieldset__title" v-if="group.Title">{{ group.Title }}</h2>
        <template v-for="field in group.Fields">
          <span v-for="i in field.Spacers" :key="i" class="spacer"></span>
          <templatedHtml
            v-if="field.Type === 'html'"
            :value="editData[field.Key]"
            :key="field.Key"
            :grid="getGridOptions(field)"
          />
          <p
            v-else-if="field.Type === 'diff'"
            v-html="jsonDiff(editData[field.Key][0], editData[field.Key][1])"
            :key="field.Key + 'diff'"
          />
          <fieldTextarea
            v-else-if="field.Type === 'text'"
            :key="field.Key + 'text'"
            :error="fieldsErrorMap.get(field.Key)"
            :grid="getGridOptions(field)"
            :disabled="isReadOnly(field)"
            :type="field.Type"
            :readonly="isReadOnly(field)"
            :autofocus="field.AutoFocus"
            :value="editData[field.Key]"
            @input="$set(editData, field.Key, $event)"
            @nextInput="focusNextInput"
            >{{ field.Title }}</fieldTextarea
          >

          <fieldState
            v-else-if="field.Type === 'state'"
            :key="field.Key + 'state'"
            :error="fieldsErrorMap.get(field.Key)"
            :grid="getGridOptions(field)"
            :type="field.Type"
            :readonly="isReadOnly(field)"
            :autofocus="field.AutoFocus"
            :forcevalue="field.ForceValue"
            :value="editData[field.Key]"
            :options="field.Choices"
            :states="field.States"
            :filters="fieldFilters.get(field.Key)"
            @input="$set(editData, field.Key, $event)"
            >{{ field.Title }}</fieldState
          >

          <fieldStateButtons
            v-else-if="field.Type === 'state-buttons'"
            :key="field.Key + 'state-buttons'"
            :error="fieldsErrorMap.get(field.Key)"
            :grid="getGridOptions(field)"
            :type="field.Type"
            :readonly="isReadOnly(field)"
            :autofocus="field.AutoFocus"
            :forcevalue="field.ForceValue"
            :value="editData[field.Key]"
            :options="field.Choices"
            :states="field.States"
            :filters="fieldFilters.get(field.Key)"
            :hasChanges="hasChanges"
            @refresh="refresh()"
            @save="save()"
            @input="$set(editData, field.Key, $event)"
            >{{ field.Title }}</fieldStateButtons
          >

          <fieldExportButtons
            v-else-if="field.Type === 'export-buttons'"
            :key="field.Key + 'export-buttons'"
            :error="fieldsErrorMap.get(field.Key)"
            :grid="getGridOptions(field)"
            :type="field.Type"
            :readonly="isReadOnly(field)"
            :autofocus="field.AutoFocus"
            :buttons="field.Buttons"
            :data="editData"
            :hasChanges="hasChanges"
            @input="$set(editData, field.Key, $event)"
            >{{ field.Title }}</fieldExportButtons
          >

          <fieldSelect
            v-else-if="field.Type === 'bool' || field.Choices"
            :key="field.Key + 'bool'"
            :error="fieldsErrorMap.get(field.Key)"
            :grid="getGridOptions(field)"
            :type="field.Type"
            :readonly="isReadOnly(field)"
            :required="field.Required"
            :autofocus="field.AutoFocus"
            :forcevalue="field.ForceValue"
            :value="editData[field.Key]"
            :options="field.Choices"
            :filters="fieldFilters.get(field.Key)"
            :description="field.Description"
            @input="$set(editData, field.Key, $event)"
            @nextInput="focusNextInput"
            >{{ field.Title }}</fieldSelect
          >

          <fieldDateTime
            v-else-if="field.Type === 'datetime'"
            :key="field.Key + 'datetime'"
            :error="fieldsErrorMap.get(field.Key)"
            :grid="getGridOptions(field)"
            :type="field.Type"
            :readonly="isReadOnly(field)"
            :autofocus="field.AutoFocus"
            :showtime="true"
            :value="editData[field.Key]"
            @input="$set(editData, field.Key, $event)"
            @nextInput="focusNextInput"
            >{{ field.Title }}</fieldDateTime
          >

          <fieldDateTime
            v-else-if="field.Type === 'date'"
            :key="field.Key + 'date'"
            :error="fieldsErrorMap.get(field.Key)"
            :grid="getGridOptions(field)"
            :type="field.Type"
            :readonly="isReadOnly(field)"
            :required="field.Required"
            :autofocus="field.AutoFocus"
            :showtime="false"
            :value="editData[field.Key]"
            @input="$set(editData, field.Key, $event)"
            @nextInput="focusNextInput"
            >{{ field.Title }}</fieldDateTime
          >

          <fieldMoney
            v-else-if="field.Type === 'money'"
            :key="field.Key + 'money'"
            :error="fieldsErrorMap.get(field.Key)"
            :grid="getGridOptions(field)"
            :disabled="isReadOnly(field)"
            :autofocus="field.AutoFocus"
            :type="field.Type"
            :disallowNegative="field.DisallowNegative"
            :disallowDecimals="field.DisallowDecimals"
            :value="editData[field.Key]"
            @input="$set(editData, field.Key, $event)"
            @nextInput="focusNextInput"
            >{{ field.Title }}</fieldMoney
          >

          <fieldDecimal
            v-else-if="field.Type === 'money' || field.Type === 'decimal'"
            :key="field.Key + 'decimal'"
            :error="fieldsErrorMap.get(field.Key)"
            :grid="getGridOptions(field)"
            :money="field.Type === 'money'"
            :disabled="isReadOnly(field)"
            :type="field.Type"
            :value="editData[field.Key]"
            @input="$set(editData, field.Key, $event)"
            @nextInput="focusNextInput"
            >{{ field.Title }}</fieldDecimal
          >

          <fieldList
            v-else-if="field.Type === 'list-string' || field.Type === 'list-int'"
            :key="field.Key + 'list'"
            :grid="getGridOptions(field)"
            :disabled="isReadOnly(field)"
            :type="field.Type"
            :value="editData[field.Key]"
          />

          <fieldArrayTable
            v-else-if="field.Type === 'array-table'"
            :key="field.Key + 'array-table'"
            :error="fieldsErrorMap.get(field.Key)"
            :grid="getGridOptions(field)"
            :type="field.Type"
            :readonly="isReadOnly(field)"
            :autofocus="field.AutoFocus"
            :forcevalue="field.ForceValue"
            :value="editData[field.Key]"
            :fields="field.SubFields"
            :options="field.Choices"
            :states="field.States"
            :filters="fieldFilters.get(field.Key)"
            @input="$set(editData, field.Key, $event)"
            >{{ field.Title }}</fieldArrayTable
          >

          <fieldInfo
            v-else-if="field.Type === 'info'"
            :key="field.Key + 'info'"
            :id="field.Key"
            :error="fieldsErrorMap.get(field.Key)"
            :grid="getGridOptions(field)"
            :type="field.Type"
            :readonly="isReadOnly(field, !field.Required || primaryKey !== 'new')"
            :autofocus="field.AutoFocus"
            :polymorphicWith="field.PolymorphicWith"
            :relatedto="field.RelatedTo"
            :relatedtocommon="field.RelatedToCommon"
            :value="editData[field.Key]"
            :filters="fieldFilters.get(field.Key)"
            :sort="field.Sort"
            :searchOptions="field.SearchOptions || undefined"
            :class="field.Class"
            :preselect="field.Preselect"
            :listOnSearch="field.ListOnSearch"
            @input="onFieldInput(field.Key, $event)"
            @nextInput="focusNextInput"
            >{{ field.Title }}</fieldInfo
          >

          <fieldManyToOne
            v-else-if="field.Type === 'many-to-one'"
            :key="field.Key + 'many-to-one'"
            :id="field.Key"
            :error="fieldsErrorMap.get(field.Key)"
            :grid="getGridOptions(field)"
            :type="field.Type"
            :readonly="isReadOnly(field, !field.Required || primaryKey !== 'new')"
            :autofocus="field.AutoFocus"
            :polymorphicWith="field.PolymorphicWith"
            :relatedto="field.RelatedTo"
            :relatedtocommon="field.RelatedToCommon"
            :value="editData[field.Key]"
            :filters="fieldFilters.get(field.Key)"
            :sort="field.Sort"
            :searchOptions="field.SearchOptions || undefined"
            :class="field.Class"
            :preselect="field.Preselect"
            :listOnSearch="field.ListOnSearch"
            :queryLocked="field.QueryLocked"
            :description="field.Description"
            @input="onFieldInput(field.Key, $event)"
            @nextInput="focusNextInput"
            >{{ field.Title }}</fieldManyToOne
          >

          <fieldOneToManyGlobal
            v-else-if="field.Type === 'one-to-many' && field.TrustFilter.FormGroup"
            :name="field.Title"
            :key="field.Key + 'one-to-many-global'"
            :error="fieldsErrorMap.get(field.Key)"
            :grid="getGridOptions(field)"
            :type="field.Type"
            :readonly="isReadOnly(field)"
            :autofocus="field.AutoFocus"
            :primarykey="primaryKey"
            :foreignkey="field.ForeignKey"
            :polymorphic="field.Polymorphic"
            :relatedto="field.RelatedTo"
            :relatedtocommon="field.RelatedToCommon"
            :description="field.Description"
            @goto="doGoto"
          >
            <template v-if="field.TableTitle" v-slot:group-title>{{ field.TableTitle }} - </template>
            <template v-slot:field-title>{{ field.Title }}</template>
          </fieldOneToManyGlobal>

          <fieldOneToMany
            v-else-if="field.Type === 'one-to-many'"
            :key="field.Key + 'one-to-many'"
            :linkKey="field.LinkKey"
            :linkRelatedto="field.LinkRelatedTo"
            :error="fieldsErrorMap.get(field.Key)"
            :grid="getGridOptions(field)"
            :type="field.Type"
            :readonly="isReadOnly(field)"
            :autofocus="field.AutoFocus"
            :primarykey="primaryKey"
            :foreignkey="field.ForeignKey"
            :polymorphic="field.Polymorphic"
            :relatedto="field.RelatedTo"
            :relatedtocommon="field.RelatedToCommon"
            :filters="fieldFilters.get(field.Key)"
            :description="field.Description"
            @goto="doGoto"
            >{{ field.Title }}</fieldOneToMany
          >

          <fieldOneToMany
            v-else-if="field.Type === 'many-to-many'"
            :key="field.Key + 'many-to-many'"
            :error="fieldsErrorMap.get(field.Key)"
            :grid="getGridOptions(field)"
            :type="field.Type"
            :readonly="isReadOnly(field)"
            :autofocus="field.AutoFocus"
            :hiddenFields="[field.ForeignKey]"
            :primarykey="primaryKey"
            :foreignkey="field.ForeignKey"
            :polymorphic="field.Polymorphic"
            :relatedto="field.ManyToManyLinker"
            :relatedtocommon="field.ManyToManyLinkerCommon"
            :description="field.Description"
            :linkKey="field.LinkKey"
            :linkRelatedto="field.LinkRelatedTo"
            @goto="doGoto"
            >{{ field.Title }}</fieldOneToMany
          >

          <fieldOneToMany
            v-else-if="field.Type === 'polymorphic'"
            :key="field.Key + 'polymorphic'"
            :error="fieldsErrorMap.get(field.Key)"
            :grid="getGridOptions(field)"
            :type="field.Type"
            :readonly="isReadOnly(field)"
            :autofocus="field.AutoFocus"
            :primarykey="primaryKey"
            :hiddenFields="[`${field.ForeignKey}Type`, `${field.ForeignKey}ID`]"
            :foreignkey="field.ForeignKey"
            :polymorphicid="editData[field.Key]"
            :polymorphic="field.Polymorphic"
            :polymorphiccommon="field.PolymorphicCommon"
            :relatedto="field.RelatedTo"
            :relatedtocommon="field.RelatedToCommon"
            :description="field.Description"
            @goto="doGoto"
            >{{ field.Title }}</fieldOneToMany
          >

          <fieldFile
            v-else-if="field.Type === 'file'"
            :key="field.Key + 'file'"
            :error="fieldsErrorMap.get(field.Key)"
            :grid="getGridOptions(field)"
            :disabled="isReadOnly(field)"
            :readonly="isReadOnly(field)"
            :autofocus="field.AutoFocus"
            :type="field.Type"
            :value="editData[field.Key]"
            @input="$set(editData, field.Key, $event)"
            >{{ field.Title }}</fieldFile
          >

          <fieldInput
            v-else
            :key="field.Key + 'input'"
            :error="fieldsErrorMap.get(field.Key)"
            :grid="getGridOptions(field)"
            :disabled="isReadOnly(field, !field.Required || primaryKey !== 'new')"
            :readonly="isReadOnly(field, !field.Required || primaryKey !== 'new')"
            :autofocus="field.AutoFocus"
            :mask="field.Mask"
            :type="field.Type"
            :value="editData[field.Key]"
            @input="$set(editData, field.Key, $event)"
            @nextInput="focusNextInput"
            >{{ field.Title }}</fieldInput
          >
        </template>
      </div>
    </form>
  </div>
</template>

<script>
import Vue from 'vue'
import fieldInput from '@/components/form-fields/fieldInput'
import fieldFile from '@/components/form-fields/fieldFile'
import fieldDecimal from '@/components/form-fields/fieldDecimal'
import fieldMoney from '@/components/form-fields/fieldMoney'
import fieldTextarea from '@/components/form-fields/fieldTextarea'
import fieldSelect from '@/components/form-fields/fieldSelect'
import fieldDateTime from '@/components/form-fields/fieldDateTime'
import fieldManyToOne from '@/components/form-fields/fieldManyToOne'
import fieldOneToMany from '@/components/form-fields/fieldOneToMany'
import fieldOneToManyGlobal from '@/components/form-fields/fieldOneToManyGlobal'
import fieldState from '@/components/form-fields/fieldState'
import fieldStateButtons from '@/components/form-fields/fieldStateButtons'
import fieldExportButtons from '@/components/form-fields/fieldExportButtons'
import fieldArrayTable from '@/components/form-fields/fieldArrayTable'
import fieldList from '@/components/form-fields/fieldList'
import fieldInfo from '@/components/form-fields/fieldInfo'
import templatedHtml from '@/components/templatedHtml'
import _ from 'lodash'
import * as jsondiffpatch from 'jsondiffpatch'

import 'jsondiffpatch/dist/formatters-styles/html.css'

let EditDataHash = null

export default Vue.extend({
  name: 'dynamicForm',
  props: {
    fullHeight: {
      type: Boolean,
      default: false
    },
    fieldsProvider: {
      type: Function,
      required: true
    },
    singleProvider: {
      type: Function
    },
    updateProvider: {
      type: Function
    },
    createProvider: {
      type: Function
    },
    removeProvider: {
      type: Function
    },
    revertProvider: {
      type: Function
    },
    execProvider: {
      type: Function
    },
    previewProvider: {
      type: Function
    },
    actionProvider: {
      type: Function
    },
    transmuteProvider: {
      type: Function
    },
    primaryKey: {
      type: String
    }
  },
  data() {
    return {
      data: null,
      editData: null,
      loading: false,
      fault: null,
      warning: null,
      fieldsErrorMap: new Map(),
      groups: [],
      actions: [],
      links: [],
      hasTransmutation: false,
      primaryKeyField: null,
      disabledKeys: new Set(),
      preview: _.debounce(function() {
        this.previewNow()
      }, 1000),
      transmute: _.debounce(function() {
        this.transmuteNow()
      }, 1000)
    }
  },
  shortcuts: {
    reload(e) {
      e.preventDefault()
      if (this.hasChanges && !window.confirm('Du har osparade ändringar.\nÄr du säker att du vill nollställa?')) {
        return
      }
      if (this.primaryKey === 'new') {
        this.refreshColumns()
        return
      }
      this.refresh()
    },
    save(e) {
      e.preventDefault()
      if (this.loading) {
        return false
      }
      if (this.createProvider && this.primaryKey === 'new') {
        this.create()
      } else if (this.updateProvider) {
        this.save()
      } else if (this.execProvider) {
        this.exec()
      }
      return false
    },
    remove(e) {
      e.preventDefault()
      if (this.loading) {
        return false
      }
      if (this.removeProvider && this.primaryKey !== 'new') {
        this.remove()
      } else if (this.primaryKey === 'new') {
        this.refreshColumns()
      }

      return false
    }
  },
  methods: {
    getGridOptions(field) {
      if (field.Grid) {
        field.Grid.Cols = field.Grid.Cols || 1
        field.Grid.Rows = field.Grid.Rows || 1
        field.Grid.Offset = field.Grid.Offset || 0
        return field.Grid
      }
      return {
        Cols: 1,
        Rows: 1,
        Offset: 0
      }
    },
    shouldShowField(field) {
      const query = this.$route.query
      // Note: Polymorphic might need to be handled differently
      const showKeys = []
      const hideKeys = []
      for (const q of field.OnQuery || []) {
        if (q.Type === 'show') {
          showKeys.push(q.Field)
        } else if (q.Type === 'hide') {
          hideKeys.push(q.Field)
        }
      }
      const showKeyInQuery = query.relationKey && query.relationValue && showKeys.includes(query.relationKey)
      const hideKeyInQuery = query.relationKey && query.relationValue && hideKeys.includes(query.relationKey)

      // Use standard Show and ShowInForm if no specific show keys are set
      if (showKeys.length > 0) {
        return showKeyInQuery
      } else if (hideKeys.length > 0) {
        return !hideKeyInQuery
      }
      return field.Show || field.ShowInForm
    },
    isReadOnly(field, additionalCheck = true) {
      const query = this.$route.query
      // Note: Polymorphic might need to be handled differently
      const lockKeys = (field.OnQuery || []).reduce((acc, q) => {
        if (q.Type === 'lock') {
          acc.push(q.Field)
        }
        return acc
      }, [])
      const keyInQuery = query.relationKey && query.relationValue && lockKeys.includes(query.relationKey)

      return this.disabledKeys.has(field.Key) || keyInQuery || (field.ReadOnly && additionalCheck)
    },
    handleDisabledFields(meta) {
      // Clear set
      this.disabledKeys.clear()
      if (meta && meta.DisabledFields) {
        for (const field of meta.DisabledFields) {
          this.disabledKeys.add(field)
        }
      }
    },
    resetError() {
      this.fault = null
      this.warning = null
      this.fieldsErrorMap = new Map()
    },
    handleError(err, onlyWarn = false) {
      this.fieldsErrorMap = new Map()
      this[!onlyWarn ? 'warning' : 'fault'] = null
      if (err instanceof Error) {
        if (err.name === 'Error') {
          err.name = 'Warning'
        }
        this[onlyWarn ? 'warning' : 'fault'] = err.toString()
      } else if (err.gql_errors) {
        const gqlErrors = new Map()
        err.gql_errors.forEach(gqlErr => {
          gqlErrors.set(gqlErr.Key, gqlErr.Message)
        })
        this.fieldsErrorMap = gqlErrors
        this[onlyWarn ? 'warning' : 'fault'] = 'Kontrollera in-data'
      } else if (err.ValidationErrors) {
        const gqlErrors = new Map()
        if (err.ValidationErrors.length === 0) {
          this.resetError()
          return
        }
        err.ValidationErrors.forEach(gqlErr => {
          gqlErrors.set(gqlErr.Key, gqlErr.Message)
        })
        this.fieldsErrorMap = gqlErrors
        this[onlyWarn ? 'warning' : 'fault'] = 'Kontrollera in-data'
      } else if (err.error && typeof err.error === 'string') {
        this[onlyWarn ? 'warning' : 'fault'] = err.error
      } else {
        this[onlyWarn ? 'warning' : 'fault'] = 'Något gick fel'
      }
    },
    jsonDiff(left, right) {
      if (left === '') {
        left = 'null'
      }
      if (right === '') {
        right = 'null'
      }
      left = JSON.parse(left)
      right = JSON.parse(right)
      const delta = jsondiffpatch.diff(left, right)
      return jsondiffpatch.formatters.html.format(delta, left)
    },
    remove() {
      if (!confirm('Är du säker att du vill ta bort?')) {
        return
      }
      this.loading = true
      this.removeProvider({ [this.primaryKeyField.Key]: this.primaryKey })
        .then(() => {
          this.loading = false
          this.resetError()
          this.$emit('removed', { [this.primaryKeyField.Key]: this.primaryKey })
        })
        .catch(err => {
          this.loading = false
          this.handleError(err)
        })
    },
    create() {
      this.loading = true
      this.editData[this.primaryKeyField.Key] = undefined // Ensure we don't have a primary key when creating
      this.createProvider(this.editData)
        .then(update => {
          this.loading = false
          this.resetError()
          this.handleError(update.Meta, true)
          if (update.Meta && update.Meta.ValidationErrors && update.Meta.ValidationErrors.length > 0) {
            return
          }
          this.editData = {}
          this.$emit('created', update.Data[this.primaryKeyField.Key])
        })
        .catch(err => {
          this.loading = false
          this.handleError(err)
        })
    },
    exec() {
      if (!this.beforeSaveOrExec()) {
        return
      }

      this.loading = true
      if (!this.editData.ID || (this.$route.params.primaryKey && this.$route.params.primaryKey !== 'new')) {
        this.editData.ID = this.$route.params.primaryKey
      }
      this.execProvider(this.editData)
        .then(update => {
          this.loading = false
          this.resetError()
          this.refreshColumns()
          this.$emit('execd', update.Data)
          this.handleError(update.Meta, true)
        })
        .catch(err => {
          this.loading = false
          this.handleError(err)
        })
    },
    previewNow() {
      this.loading = true
      if (!this.editData) {
        this.editData = {}
      }
      if (!this.editData.ID || (this.$route.params.primaryKey && this.$route.params.primaryKey !== 'new')) {
        this.editData.ID = this.$route.params.primaryKey
      }
      this.previewProvider(this.editData)
        .then(update => {
          this.loading = false
          this.resetError()
          for (const key in update.Data) {
            if (update.Data[key] !== this.editData[key]) {
              this.$set(this.editData, key, update.Data[key])
            }
          }
          this.$emit('previewd', this.editData)
          this.handleError(update.Meta, true)
        })
        .catch(err => {
          this.loading = false
          this.handleError(err, true)
        })
    },
    transmuteNow() {
      if (!this.hasTransmutation) {
        return
      }

      this.loading = true
      if (!this.editData.ID || (this.$route.params.primaryKey && this.$route.params.primaryKey !== 'new')) {
        this.editData.ID = this.$route.params.primaryKey
      }
      this.transmuteProvider(this.editData)
        .then(update => {
          this.loading = false
          for (const key in update.Data) {
            if (update.Data[key] !== this.editData[key]) {
              this.$set(this.editData, key, update.Data[key])
            }
          }
          this.resetError()
          this.$emit('transmuted', this.editData)
          this.handleDisabledFields(update.Meta)
        })
        .catch(err => {
          this.loading = false
          this.handleError(err, true)
        })
    },
    save() {
      if (!this.beforeSaveOrExec()) {
        return
      }

      this.loading = true
      this.updateProvider(this.editData)
        .then(update => {
          this.loading = false
          this.resetError()
          this.handleError(update.Meta, true)
          // if we have any errors, we should not emit the saved event
          if (update.Meta && update.Meta.ValidationErrors && update.Meta.ValidationErrors.length > 0) {
            return
          }
          this.$emit('saved', { changes: [this.data, this.editData] })
          return this.refresh()
        })
        .catch(err => {
          this.loading = false
          this.handleError(err)
        })
    },
    beforeSaveOrExec() {
      if (this.editData && this.editData.Notices) {
        for (const message of this.editData.Notices) {
          // Check if notice has already been accepted
          if (this.$store.getters.noticeAccepted(message)) {
            continue
          }

          this.$store.dispatch('setNotice', message)
          if (!confirm(message)) {
            return false
          }
        }
        this.$store.dispatch('resetNotices')
      }

      return true
    },
    refresh() {
      if (this.providerCtx === null) {
        return Promise.reject(new Error('ERR_NO_PROVIDER_CTX'))
      }
      if (this.primaryKey === 'new') {
        return Promise.reject(new Error('ERR_REFRESH_NEW'))
      }
      if (!this.singleProvider) {
        return Promise.reject(new Error('ERR_NO_SINGLE_PROVIDER'))
      }
      this.loading = true
      return this.singleProvider(this.providerCtx)
        .then(row => {
          this.loading = false
          this.resetError()
          this.data = row.Data
          this.handleError(row.Meta, true)
        })
        .catch(err => {
          this.loading = false
          this.handleError(err)
        })
    },
    refreshColumns() {
      this.editData = null
      this.groups = []
      this.actions = []
      this.links = []
      this.hasTransmutation = false

      this.fieldsProvider({})
        .then(({ Entity, Sections }) => {
          this.resetError()

          this.hasTransmutation = Entity.HasTransmutation
          this.groups = Sections
          for (const key in Entity.Actions) {
            if (Entity.Actions[key].Hidden) {
              continue
            }
            this.actions.push({
              Action: key,
              ...Entity.Actions[key]
            })
          }

          this.links = Entity.Links

          var columns = []
          this.groups.forEach(section => {
            section.Fields.forEach(field => {
              if (field.IsPrimary) {
                this.primaryKeyField = field
              }
              if (this.shouldShowField(field) && this.fieldDependenciesMet(field)) {
                columns.push(field)
              }
            })
          })

          if (!this.singleProvider || this.primaryKey === 'new') {
            const hasRelationQuery = this.$route.query.relationKey && this.$route.query.relationValue
            this.data = {}
            columns.forEach(column => {
              if (column.Key === '') {
                return
              }
              let def = column.DefaultValue
              if (hasRelationQuery) {
                def = column.Choices?.find(c => c.DefaultOnQuery?.includes(this.$route.query.relationKey))?.Value ?? def
              }
              this.data[column.Key] = def
            })
            if (hasRelationQuery) {
              if (this.$route.query.relationPolymorph) {
                this.data[`${this.$route.query.relationKey}ID`] = this.$route.query.relationValue
                this.data[`${this.$route.query.relationKey}Type`] = this.$route.query.relationPolymorph
              } else {
                this.data[this.$route.query.relationKey] = this.$route.query.relationValue
              }
            }
          } else {
            this.$nextTick(this.refresh)
          }
        })
        .catch(err => {
          this.loading = false
          this.handleError(err)
        })
    },
    doAction(action) {
      if (
        this.hasChanges &&
        !window.confirm(
          'Du har osparade ändringar.\nÄr du säker att du vill fortsätta utan att spara först? Ändringar kan gå förlorade'
        )
      ) {
        return
      }
      if (!window.confirm('Du kommer nu att ' + action.Name + '\n\nVill du fortsätta?')) {
        return
      }
      this.actionProvider(action.Action, this.primaryKey)
    },
    doLink(link) {
      if (!this.data) return

      if (
        this.hasChanges &&
        !window.confirm(
          'Du har osparade ändringar.\nÄr du säker att du vill fortsätta utan att spara först? Ändringar kan gå förlorade'
        )
      ) {
        return
      }

      const q = link.Link
      q.params = q.params || {}
      q.params.trust = this.$route.params.trust
      q.query = {
        relationKey: link.RelationKey,
        relationValue: this.data[link.RelationValueKey]
      }

      this.$router.push(q)
    },
    doGoto(callback) {
      // Wrapper function to allow children to safely navigate away
      if (!this.data) return

      if (
        this.hasChanges &&
        !window.confirm(
          'Du har osparade ändringar.\nÄr du säker att du vill fortsätta utan att spara först? Ändringar kan gå förlorade'
        )
      ) {
        return
      }

      callback()
    },
    fieldDependenciesMet(field) {
      if (!field.Dependencies || !field.Dependencies.Fields || field.Dependencies.Fields.length === 0) {
        return true
      }

      // Check if all dependencies are met
      const checked = field.Dependencies.Fields.reduce((acc, dep) => {
        acc.push(!(this.editData && this.editData?.[dep.Field] !== dep.Value))
        return acc
      }, [])

      let conditionsMet = false
      switch (field.Dependencies.ConditionOperator) {
        case 'NOR':
          conditionsMet = !checked.some(v => v)
          break
        case 'NAND':
          conditionsMet = !checked.every(v => v)
          break
        case 'OR':
          conditionsMet = checked.some(v => v)
          break
        case 'AND':
        // Fallthrough as the default is AND
        default:
          conditionsMet = checked.every(v => v)
      }

      // Reset field if conditions are not met
      if (!conditionsMet && this.editData && field.Key in this.editData) {
        this.editData[field.Key] = field.DefaultValue
      }

      return conditionsMet
    },
    onFieldInput(key, e) {
      if (e === null) {
        // Check if field has any dependencies
        const field = this.fieldsByKey[key]
        if (field.Filters) {
          // Clear all dependencies as well
          for (const f of field.Filters) {
            const filterField = this.fieldsByKey[f.From]
            if (f.Type !== 'dependent' || !filterField) continue
            this.$set(this.editData, filterField.Key, null)
          }
        }
      }

      this.$set(this.editData, key, e)
    },
    focusNextInput(e) {
      const ref = this.$refs.formRef
      if (!ref) return
      // Find all focusable children of form
      const focusableChildren = ref.querySelectorAll(
        `:not(.disabled) > input:not([disabled]),
         :not(.disabled) > textarea:not([disabled]),
         :not(.disabled) > select:not([disabled]),
         :not(.disabled) > [tabindex]:not([tabindex = "-1"])
         :not(.vti__dropdown) > [tabindex = "0"]`
      )

      // Filter children because css selecting is limited
      const focusable = Array.prototype.filter.call(focusableChildren, function(child) {
        // if has a parent with .skip-selection, then don't focus
        if (child.closest('.skip-selection')) return false
        return true
      })

      // Convert NodeList to Array and fet current focused item's index
      const focusedIndex = focusable.indexOf(document.activeElement)

      // Get next focusable item and focus it
      const next = focusable[focusedIndex + 1]

      if (next) {
        next.focus()
        // Select text if it's an input
        if (next.tagName === 'INPUT') {
          this.$nextTick(() => {
            next.select()
          })
        }
      } else {
        // Focus exec button
        const action = this.$refs.execButton || this.$refs.saveButton || this.$refs.createButton
        if (action) {
          action.focus()
        }
      }
    }
  },
  mounted() {
    this.refreshColumns()
  },
  computed: {
    providerCtx() {
      if (this.primaryKeyField === null) {
        return null
      }
      return {
        filters: new Map([[`${this.primaryKeyField.Key}`, [this.primaryKey]]])
      }
    },
    formGroups() {
      return this.groups.map(g => {
        return _.cloneDeep({
          Title: g.Title,
          Cols: g.Cols,
          Inline: g.Inline,
          Fields: g.Fields.filter(field => {
            if (
              this.$route.query.relationPolymorph &&
              [`${this.$route.query.relationKey}ID`, `${this.$route.query.relationKey}Type`].includes(field.Key)
            ) {
              return false
            }
            return this.shouldShowField(field) && this.fieldDependenciesMet(field)
          })
        })
      })
    },
    hasChanges() {
      if (this.data === null) {
        return false
      }
      let hasChange = false
      for (const key in this.editData) {
        if (this.data[key] !== this.editData[key]) {
          // Allow loose comparison
          if (!this.data[key] && !this.editData[key]) {
            continue
          }
          hasChange = true
          break
        }
      }
      return hasChange
    },
    fieldsByKey() {
      const fields = {}

      for (let i = 0; i < this.formGroups.length; i++) {
        const group = this.formGroups[i]
        for (let j = 0; j < group.Fields.length; j++) {
          const field = group.Fields[j]
          fields[field.Key] = field
        }
      }

      return fields
    },
    fieldFilters() {
      const filters = new Map()

      const formGroups = _.cloneDeep(this.formGroups)

      for (let i = 0; i < formGroups.length; i++) {
        const group = formGroups[i]
        for (let j = 0; j < group.Fields.length; j++) {
          const field = group.Fields[j]
          if (field.Filters) {
            filters.set(
              field.Key,
              field.Filters.reduce((arr, filter) => {
                switch (filter.Type) {
                  case 'session': {
                    const val = filter.From.split('.').reduce((obj, key) => {
                      return obj[key]
                    }, this.$store.getters.session || {})
                    filter.Value = typeof val === 'string' ? val : null
                    break
                  }
                  case 'dependent':
                    filter.Value = this.editData[filter.From] ? ':=' + this.editData[filter.From] : null
                    break
                  case 'conditional':
                    if (this.editData[filter.From] === undefined) break

                    if (this.editData[filter.From] && filter.Resolved.IsTrue != null) {
                      for (const key in filter.Resolved.IsTrue) {
                        if (key === 'Value') field.ForceValue = filter.Resolved.IsTrue[key]
                        else field[key] = filter.Resolved.IsTrue[key]
                      }
                    } else if (filter.Resolved.IsFalse != null) {
                      for (const key in filter.Resolved.IsFalse) {
                        if (key === 'Value') field.ForceValue = filter.Resolved.IsFalse[key]
                        else field[key] = filter.Resolved.IsFalse[key]
                      }
                    }

                    break
                  case 'subquery':
                    filter.Value = '::' + filter.Query
                    filter.Query = filter.From
                    break
                  case 'static':
                    // Do nothing
                    break
                }
                if (filter.Value) arr.push(filter)
                return arr
              }, [])
            )
          }
        }
      }
      return filters
    },
    backUrl() {
      return this.$route.query.back || this.$routeHistory[this.$routeHistory.length - 1]
    }
  },
  watch: {
    providerCtx() {
      // if it's a "do" action, we shouldn't refresh
      if (this.$route.name === 'do') {
        return
      }

      this.refresh()
      /*
      .catch((err) => {
        switch (err.message) {
          case 'ERR_NO_PROVIDER_CTX':
            console.error('no provider ctx', err)
            break
          case 'ERR_REFRESH_NEW':
            // console.warn('can\'t refresh if not saved', err)
            break
          case 'ERR_NO_SINGLE_PROVIDER':
            console.error('no single provider', err)
            break
          default:
            throw err
        }
      })
      */
    },
    data() {
      if (this.editData === null) {
        this.editData = {}
      }
      for (const key in this.data) {
        this.$set(this.editData, key, this.data[key])
      }
      for (const key in this.editData) {
        if (!(key in this.data)) {
          this.$delete(this.editData, key)
        }
      }

      // Autofocus handler
      this.$nextTick(() => {
        // Find the first field with autofocus and focus it
        const autofocus = document.querySelector('[autofocus]')?.querySelector('input, textarea, select')
        if (autofocus) {
          autofocus.click()
          autofocus.focus()
        }
      })
    },
    editData: {
      handler(oldVal, newVal) {
        // This is quite dumb, but it works
        const newEditDataHash = JSON.stringify(newVal)

        if (EditDataHash === newEditDataHash) {
          return
        }
        EditDataHash = newEditDataHash

        if (this.previewProvider) {
          this.preview()
        }
        if (this.transmuteProvider) {
          this.transmute()
        }
      },
      deep: true
    },
    fieldsProvider() {
      this.refreshColumns()
    }
  },
  components: {
    fieldInput,
    fieldFile,
    fieldDecimal,
    fieldMoney,
    fieldTextarea,
    fieldSelect,
    fieldDateTime,
    fieldManyToOne,
    fieldOneToMany,
    fieldOneToManyGlobal,
    fieldState,
    fieldStateButtons,
    fieldExportButtons,
    fieldArrayTable,
    fieldList,
    fieldInfo,
    templatedHtml
  }
})
</script>

<style lang="scss" scoped="">
.button-container {
  position: fixed;
  top: 0.5rem;
  right: 0.5rem;
  z-index: 10;
}
.message-container {
  top: 1.5rem;
  left: 50%;
  position: fixed;
  z-index: 10;
  background-color: #012169;
  color: white;
  padding: 0.5rem 1.5rem;
  transform: translate(-50%, 0);
}
.save-button {
  background: var(--main-color);
  color: #fff;
  border-width: 0;
  padding: 1rem 1.5rem;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  &:hover,
  &:focus {
    background: var(--blue-dark);
  }

  &[disabled] {
    opacity: 0.4;
    cursor: not-allowed;
  }

  margin-left: 0.5rem;
}
.back-button {
  background: var(--main-color);
  color: #fff;
  border-width: 0;
  padding: 1rem 1.5rem;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  &:hover {
    background: var(--blue-dark);
  }

  &[disabled] {
    opacity: 0.4;
    cursor: not-allowed;
  }

  margin-left: 0.5rem;
}
.remove-button {
  background: #ff6c6c;
  color: #fff;
  border-width: 0;
  padding: 1rem 1.5rem;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  &:hover {
    background: #ec4444;
  }

  &:not(:first-child) {
    margin-left: 0.5rem;
  }
}
.action-button {
  background: var(--blue-dark);
  color: #fff;
  border-width: 0;
  padding: 1rem 1.5rem;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  &:hover {
    background: var(--blue-dark);
  }

  &[disabled] {
    opacity: 0.4;
    cursor: not-allowed;
  }

  margin-left: 0.5rem;
}

.save-button:focus {
  animation: pulse 1s;
}

.fieldset.is-inline-block {
  display: inline-block !important;
  margin-right: 2rem;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.7);
  }
  70% {
    transform: scale(1.2) translate(-10%, 15%);
    box-shadow: 0 0 0 10px rgba(0, 123, 255, 0);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
  }
}
</style>
