export default {
  state: {
    notices: []
  },
  mutations: {
    setNotice(state, message) {
      // Find notice with same message, else create new notice
      const notice = state.notices.find(notice => notice.message === message) ?? {}
      notice.message = message
      notice.accepted = false
    },
    acceptNotice(state, message) {
      const notice = state.notices.find(notice => notice.message === message)
      if (!notice) {
        return
      }
      notice.accepted = true
    },
    removeNotice(state, message) {
      const index = state.notices.findIndex(notice => notice.message === message)
      if (index === -1) {
        return
      }
      state.notices.splice(index, 1)
    },
    resetNotice(state) {
      state.notices = []
    }
  },
  actions: {
    setNotice({ commit, dispatch }, message) {
      commit('setNotice', message)
    },
    acceptNotice({ commit, dispatch }, message) {
      commit('acceptNotice')
    },
    removeNotice({ commit, dispatch }, message) {
      commit('removeNotice')
    },
    resetNotices({ commit, dispatch }) {
      commit('resetNotice')
    }
  },
  getters: {
    notices(state) {
      return state.notices
    },
    noticeAccepted(state) {
      return message => {
        const notice = state.notices.find(notice => notice.message === message)
        if (!notice) {
          return false
        }
        return notice.accepted
      }
    }
  }
}
