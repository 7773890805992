<template>
  <router-view />
</template>

<script>
export default {
  created () {
    this.fetchData()
  },
  watch: {
    trust: 'fetchData'
  },
  methods: {
    fetchData () {
      this.$store.dispatch('currentTrust', this.trust)
    }
  },
  computed: {
    trust () {
      return this.$route.params.trust
    }
  }
}
</script>
