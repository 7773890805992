<template>
  <div
    class="field field--select"
    :style="{ '--form-item-width': grid.Cols, '--form-item-height': grid.Rows, '--form-item-start': grid.Offset }"
  >
    <label :for="id" class="field__label">
      <slot></slot>
    </label>
    <table>
      <thead>
        <tr>
          <th v-for="field of fields" :key="`at_th${id}_${field.Key}`">{{ field.Title || field.Key }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, i) of value" :key="`at_tr${id}${i}`">
          <td v-for="field of fields" :key="`at_tr${id}${i}_${field.Key}`">
            <span v-if="field.Type === 'money'">
              <span
                :style="{
                  'background-color': parseFloat(row[field.Key]) > 0 ? '#E9FAE5' : '#FAE5E5',
                  padding: '4px 7px',
                  'border-radius': '7px',
                  'font-family': 'DroidSansMonoSlashed'
                }"
              >
                <i18n-n :value="parseFloat(row[field.Key])" format="currency" locale="sv-SE">
                  <template v-slot:currency="slotProps"
                    ><span style="color: #aaa">{{ slotProps.currency }}</span></template
                  >
                  <template v-slot:fraction="slotProps"
                    ><span style="color: #aaa;">{{ slotProps.fraction }}</span></template
                  >
                  <template v-slot:decimal="slotProps"
                    ><span style="color: #aaa;">{{ slotProps.decimal }}</span></template
                  >
                </i18n-n>
              </span>
              <template v-if="amountOfDecimals(row[field.Key]) > 2">
                <br />
                <span style="color: #f28800;font-style: italic;font-size: 10px;">Observera avrundning</span>
              </template>
            </span>
            <span v-else>{{ row[field.Key] }}</span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'field-array-table',
  props: {
    error: String,
    grid: Object,
    id: String,
    name: String,
    options: Array,
    states: Object,
    fields: Array,
    value: Array,
    forcevalue: Array
  },
  computed: {},
  methods: {
    amountOfDecimals(value) {
      if (!value) {
        return 0
      }
      return (
        Number(value)
          .toString()
          .split('.')[1] || ''
      ).length
    }
  },
  components: {}
}
</script>

<style lang="scss" scoped>
.text {
  display: inline-block;
  // color: #fff;
  background-color: var(--green);
  border-radius: 1em;
  border: 0;
  // padding: 0.5rem;
  padding: 0.5em 0.75em;
  // margin-bottom: 1rem;
}
</style>
