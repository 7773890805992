import ProfileApi from '@/api/ProfileApi'

const getDefaultState = () => {
  const session = (typeof sessionStorage !== 'undefined') ? JSON.parse(sessionStorage.getItem('session')) : null
  return {
    session: session,
    api: session !== null ? new ProfileApi(session.token) : null,
    error: null
  }
}

const state = getDefaultState()

export default {
  state,
  mutations: {
    setSession (state, session) {
      if (!session) {
        sessionStorage.removeItem('session')
      } else {
        sessionStorage.setItem('session', JSON.stringify(session))
      }
      state.session = session
      state.api = new ProfileApi(session.token)
    },
    reset (state) {
      sessionStorage.removeItem('session')
      Object.assign(state, getDefaultState())
    }
  },
  actions: {
    newSession ({ commit, dispatch }, data) {
      commit('setSession', data)
    },
    logout ({ commit, dispatch }) {
      commit('reset')
    },
    clear ({ commit, dispatch }) {
      commit('reset')
    }
  },
  getters: {
    isLoggedIn: state => state.session != null,
    session: state => state.session,
    api: state => state.api,
    accessToken: state => state.session != null ? state.session.token : null,
    errorMessage: state => state.error !== null ? state.error.toString() : null
  }
}
