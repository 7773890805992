<template>
  <div
    v-if="value !== null && value !== ''"
    class="field"
    :style="{ '--form-item-width': grid.Cols, '--form-item-height': grid.Rows, '--form-item-start': grid.Offset }"
  >
    <component :is="renderedComponents" />
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  props: {
    value: {
      type: String,
      required: false,
      default: () => ''
    },
    grid: Object
  },
  computed: {
    renderedComponents() {
      return Vue.extend({ template: '<div>' + this.value + '</div>' })
    }
  }
})
</script>
