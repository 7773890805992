import axios from 'axios'

export default class BasicAuth {
  constructor () {
    const client = axios.create({
      baseURL: process.env.VUE_APP_PROFILE_API + '/auth/basic/profile/',
      timeout: 3000,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    this.client = client
  }

  /**
   * Try to obtain a session
   *
   * Params:
   *   request = {
   *     username string // Required
   *     password      string   // Required
   *   }
   */
  login (request) {
    return this.client.post('/login', request)
  }
}
