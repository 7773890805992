<template>
  <div
    class="field field--text"
    :style="{ '--form-item-width': grid.Cols, '--form-item-height': grid.Rows, '--form-item-start': grid.Offset }"
  >
    <label :for="id" class="field__label">
      <slot></slot>
    </label>
    <div>
      <a class="button is-primary" target="_BLANK" :href="value">Öppna</a>
      <small v-if="error" style="color: red; font-style: italic">{{ error }}</small>
    </div>
  </div>
</template>

<script>
export default {
  name: 'field-file',
  props: {
    error: String,
    grid: Object,
    disabled: Boolean,
    id: String,
    name: String,
    placeholder: String,
    readonly: Boolean,
    type: String,
    value: [String]
  },
  methods: {
    update(newValue) {
      this.$emit('input', newValue)
    }
  }
}
</script>
