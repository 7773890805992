<template>
  <div class="overlay" v-if="(needsReAuth || droppedConnection) && $route.name != 'auth'">
    <div class="blur"></div>
    <div class="body" v-if="needsReAuth">
      <h1>Din session har gått ut</h1>
      <router-link :to="{name: 'auth', query: { redirect_to: redirectPath }}">Logga in</router-link>
    </div>
    <div class="body" v-else-if="droppedConnection">
      <h1>Tappade kontakten med servern</h1>
      <p>Återansluter automatiskt, alt. <a href="#" @click.prevent="checkAuth">försök igen nu</a></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'reauth-modal',
  data () {
    return {
      needsReAuth: false,
      droppedConnection: false,
      checkAuthInterval: null
    }
  },
  computed: {
    redirectPath () {
      return this.$route.fullPath
    }
  },
  methods: {
    checkAuth () {
      if (this.$route.name !== 'auth') {
        this.needsReAuth = false
      } else {
        return
      }

      this.$store.getters.api.checkToken()
        .then(res => {
          this.needsReAuth = !res
          this.droppedConnection = false
        })
        .catch(() => {
          this.droppedConnection = true
        })
    }
  },
  mounted () {
    this.checkAuth()
    this.checkAuthInterval = setInterval(this.checkAuth, 20000)
  },
  beforeDestroy () {
    clearInterval(this.checkAuthInterval)
  }
}
</script>

<style scoped>
.switch-method {
    background: none;
    border: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    color: #888;
    text-decoration: underline;
}
.overlay {
  .blur {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 99991;
    background-color: #011d5c;
  }
  .body {
    z-index: 99992;
    color: white;
    margin: auto;
    width: 24rem;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    h1 {
      font-size: 30px;
      margin-bottom: 1rem;
    }
    a {
      color: white;
    }
    p {
      margin: 1rem 0;
    }
  }
}
</style>
