<template>
  <loginContainer title="Välj stiftelse">
    <ul>
      <li v-for="trust in trustsWithoutFakes" :key="trust.ID">
        <router-link :to="{name: 'start', params: {trust: trust.Slug} }">{{ trust.Name }}</router-link>
      </li>
    </ul>
    <button class="logout" v-on:click="logout()">Logga ut</button>
  </loginContainer>
</template>

<script>
import CommonApi from '@/api/CommonApi'
import loginContainer from '@/components/loginContainer'

export default {
  name: 'home',
  data () {
    return {
      trusts: []
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('logout')
      this.$router.push({ name: 'auth' })
    }
  },
  computed: {
    trustsWithoutFakes () {
      return this.trusts.filter(t => !t.Fake)
    },
    commonApi () {
      if (!this.$store.getters.accessToken) {
        return null
      }
      return new CommonApi(this.$store.getters.accessToken)
    }
  },
  asyncComputed: {
    trusts: {
      async get () {
        if (!this.commonApi) {
          return []
        }
        const response = await this.commonApi.trusts()
        return response.data.trusts
      },
      default: []
    }
  },
  components: { loginContainer }
}
</script>

<style scoped>
a {
  margin-bottom: 0.8rem;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  height: auto;
  display: flex;
  width: 100%;
  background-color: #012169;
  border-color: transparent;
  border-width: 1px;
  cursor: pointer;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  padding-left: 0.75em;
  padding-right: 0.75em;
  border-radius: 4px;
  border: 1px solid transparent;
}
a:hover {
    background-color: #011d5c;
    border-color: transparent;
    color: #fff;
}
a:active {
    background-color: #011950;
    border-color: transparent;
    color: #fff;
}
.logout {
    background: none;
    border: none;
    cursor: pointer;
    margin: 0;
    padding: 0;
    color: #888;
    text-decoration: underline;
}
</style>
