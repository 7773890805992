<template>
  <div class="main">
    <div v-if="error !== null" class="message-container" role="alert">
      <span style="font-size: 20px; color: rgb(255, 108, 108)">{{ error }}</span>
    </div>
    <form class="form" style="min-height: 100vh;">
      <div class="fieldset">
        <div class="field field--text" style="--form-item-width:1;">
          <label class="field__label">Exportera utbetalning</label>
          <span class="valid" style="display: flex;">
            <button class="button action-button" @click.prevent="exportFile('sus')">SUS</button>
            <button class="button action-button" @click.prevent="exportFile('ska')">Skatt</button>
            <button class="button action-button" @click.prevent="exportFile('iso20022')">ISO20022</button>
          </span>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import fileDownload from 'js-file-download'

export default {
  name: 'payout-export',
  data () {
    return {
      error: null
    }
  },
  computed: {
    apiClient () {
      return this.$store.getters.api.trust(this.$route.params.trust)
    }
  },
  methods: {
    /**
     * @param {string} type sus or iso20022
     */
    exportFile (type) {
      this.error = null

      let fileName = 'utbetalning.dat'
      if (type === 'sus') {
        fileName = 'utbetalning.sus'
      } else if (type === 'ska') {
        fileName = 'utbetalning.ska'
      } else if (type === 'iso20022') {
        fileName = 'utbetalning.xml'
      }
      this.apiClient.payoutExport(type, this.$route.params.primaryKey)
        .then(resp => {
          if (resp.headers['content-disposition'] !== undefined) {
            fileName = resp.headers['content-disposition'].split('filename=')[1].replace(/['"]+/g, '')
          }
          fileDownload(resp.data, fileName)
          this.$router.go(-1)
        })
        .catch(err => {
          if (err.response) {
            const fr = new FileReader()
            fr.onload = () => {
              this.error = JSON.parse(fr.result).error
            }
            fr.readAsText(err.response.data)
          } else {
            this.error = err.message
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.message-container {
  top: 1.5rem;
  left: 50%;
  position: fixed;
  z-index: 10;
  background-color:  #012169;
  color: white;
  padding: 0.5rem 1.5rem;
  transform: translate(-50%, 0);
}
.action-button {
    background: var(--blue-dark);
    color: #fff;
    border-width: 0;
    padding: 1rem 1.5rem;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    margin-left: 0.5rem;
}
</style>
