<template>
  <div
    class="field field--text"
    :style="{ '--form-item-width': grid.Cols, '--form-item-height': grid.Rows, '--form-item-start': grid.Offset }"
  >
    <template v-for="provider in providers">
      <div v-show="visible[provider.key]" :key="provider.key" class="fieldset">
        <h2 v-if="provider.label" class="fieldset__title"><slot name="group-title" />{{ provider.label }}</h2>
        <div class="field field--text" :style="{ '--form-item-width': 3 }">
          <label :for="id" class="field__label">
            <slot name="field-title" />
            <font-awesome-icon v-if="description" icon="info-circle" class="info-icon" v-description="description" />
          </label>
          <small v-if="primarykey === 'new'" style="font-style: italic;"
            >Detta fält kan inte administreras innan du sparat</small
          >
          <dynamicTable
            :listProvider="provider.list"
            :fieldsProvider="provider.fields"
            :filtering="false"
            :showCreateButton="!readonly"
            :onRowClick="
              e => {
                provider.active ? gotoSingle(provider, e) : fromDifferentTrustNotice(provider)
              }
            "
            :hiddenFields="hiddenFields"
            :allTrusts="true"
            @rows-updated="$set(visible, provider.key, $event.length > 0)"
            @create="provider.active ? gotoSingle(provider, 'new') : fromDifferentTrustNotice(provider)"
          >
            <template v-slot:add-new-button="">
              <span style="font-size: 14px;">Ny i {{ provider.label }}</span>
            </template>
          </dynamicTable>
        </div>
      </div>
    </template>
    <small v-if="error" style="color: red; font-style: italic; margin-left: 5px;">{{ error }}</small>
  </div>
</template>

<script>
import dynamicTable from '@/components/dynamicTable'

export default {
  name: 'field-one-to-many-global',
  props: {
    error: String,
    grid: Object,
    id: String,
    name: String,
    readonly: Boolean,
    primarykey: String,
    foreignkey: String,
    relatedto: String,
    relatedtocommon: Boolean,
    polymorphic: String,
    polymorphicid: String,
    polymorphiccommon: Boolean,
    description: String,
    hiddenFields: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      trusts: [],
      visible: {}
    }
  },
  methods: {
    gotoSingle(provider, primaryKey) {
      // we use 'goto' to ensure we can safely navigate away from the form
      this.$emit('goto', () => {
        const route = {
          name: provider.iscommon ? 'common-basregister-single' : 'basregister-single',
          params: {
            type: provider.type,
            trust: !provider.iscommon && provider.trust ? provider.trust.Slug : this.$route.params.trust,
            primaryKey: `${primaryKey}`
          },
          query: {
            relationKey: this.foreignkey,
            relationValue: this.primarykey,
            relationPolymorph: this.polymorphic ? this.$route.params.type : null,
            back: this.$route.path
          }
        }
        this.$router.push(route)
      })
    },
    fromDifferentTrustNotice(provider) {
      if (confirm(`Den här relationen är låst till ${provider.trust.Name}. Vill du byta?`)) {
        this.$router.push({
          name: 'common-basregister-single',
          params: {
            trust: provider.trust.Slug,
            primaryKey: this.primarykey
          }
        })
      }
    }
  },
  computed: {
    providers() {
      if (this.$store.getters.api === null) {
        return null
      }

      var prov = []

      for (let i = 0; i < this.trusts.length; i++) {
        const trust = this.trusts[i]
        const listProvider = this.$store.getters.api.trust(trust.Slug).listProvider(this.relatedto)
        const p = [
          {
            key: `${trust.ID}_${this.relatedto}`,
            label: `${trust.Name}`,
            type: this.relatedto,
            trust: trust,
            fields: this.$store.getters.api.trust(trust.Slug).fieldsProvider(this.relatedto),
            active: this.$route.params.trust === trust.Slug,
            list: query => {
              query.filters = query.filters || new Map()

              if (this.polymorphic) {
                query.filters.set(`${this.foreignkey}Type`, [`==${this.$route.params.type}`])
                query.filters.set(`${this.foreignkey}ID`, [`==${this.primarykey}`])
              } else {
                query.filters.set(this.foreignkey, [`==${this.primarykey}`])
              }

              return listProvider(query)
            }
          }
        ]
        prov = [...prov, ...p]
      }
      return prov
    }
  },
  mounted() {
    this.$store.getters.api
      .common()
      .trusts()
      .then(resp => {
        this.trusts = resp.data.trusts
      })
  },
  components: {
    dynamicTable
  }
}
</script>
