<template>
  <div v-if="polymorphicWith"></div>
  <div
    v-else
    class="field field--select"
    :style="{ '--form-item-width': grid.Cols, '--form-item-height': grid.Rows, '--form-item-start': grid.Offset }"
  >
    <fieldSelect
      ref="select"
      :grid="grid"
      :value="value"
      :options="choices"
      :readonly="readonly"
      :searching="searching"
      :listOnSearch="cListOnSearch"
      :description="description"
      @search="onSearch"
      @input="update($event)"
      @nextInput="$emit('nextInput')"
    >
      <slot></slot>
      <button v-if="!readonly && value !== null" @click.prevent="clear">Rensa</button>
      <small v-if="error" style="color: red; font-style: italic; margin-left: 5px;">{{ error }}</small>
    </fieldSelect>
  </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import fieldSelect from './fieldSelect'

export default {
  name: 'field-many-to-one',
  props: {
    error: String,
    grid: Object,
    id: String,
    options: Array,
    readonly: Boolean,
    value: String,
    relatedto: String,
    relatedtocommon: Boolean,
    polymorphicWith: Array,
    preselect: Boolean,
    listOnSearch: Boolean,
    description: String,
    filters: {
      type: Array,
      default: () => []
    },
    sort: {
      type: Object,
      default: () => ({})
    },
    searchOptions: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      choices: [],
      oldFilters: null,
      searching: false,
      uniqueid: _.uniqueId('field-many-to-one-' + this.id + '-' + this.relatedto),
      debounceSearch: _.debounce((search, useDefaultSelector = false) => {
        // Format filters
        const filters = this.filters.reduce((map, filter) => {
          map.set(filter.Query, filter.Value)
          return map
        }, new Map())

        // // Add selected value to filter
        // if (search === '' && this.value) {
        //   filters.set('ID', this.value)
        // }

        this.listProvider({
          searchOptions: this.cSearchOptions,
          search: search,
          filters: filters,
          sortBy: this.sort.Key || null,
          sortDesc: this.sort.Direction === 'DESC' || false,
          uniqueid: this.uniqueid,
          selected: this.value,
          skipComputedFields: true // Speeds up the search, but removes the ability to search on computed fields
        })
          .then(({ Results: results }) => {
            this.searching = false
            this.choices = results.map(this.choiceResolver)
            if (useDefaultSelector && this.choices.length === 1) {
              this.update(this.choices[0].Value)
            }
          })
          .catch(err => {
            this.searching = false
            if (axios.isCancel(err)) {
              return
            }
            throw err
          })
      }, 150)
    }
  },
  methods: {
    search(search, useDefaultSelector = false) {
      this.searching = true
      this.debounceSearch(search, useDefaultSelector)
    },
    clear() {
      this.update(null)
      // Focus field
      this.$nextTick(() => {
        this.$refs.select.$el.querySelector('input').focus()
      })
    },
    update(newValue) {
      if (this.type === 'number') {
        newValue = parseInt(newValue)
      }
      this.$emit('input', newValue)
    },
    onSearch(search) {
      this.choices = [] // Clear choices, so we don't accidentally select an old result
      this.search(search)
    },
    choiceResolver({ Data: row }) {
      if (this.relatedto === 'Employer') {
        return {
          Value: row.ID,
          Title: `${row.Fnr || row.ShortID} - ${row.Name}`
        }
      } else if (this.relatedto === 'Pensionable') {
        return {
          Value: row.ID,
          Title: `${row.PersonalNumber.replace(/\d{4}$/, '-$&')} - ${row.FullName}`
        }
      } else if (this.relatedto === 'Account') {
        return {
          Value: row.ID,
          Title: `${row.Number} - ${row.Name}`
        }
      } else if (this.relatedto === 'Instrument') {
        return {
          Value: row.ID,
          Title: `${row.Name}`
        }
      } else if (this.relatedto === 'TransactionType') {
        return {
          Value: row.ID,
          Title: row.InternalID ? `${row.InternalID} - ${row.Name}` : `${row.ShortID} - ${row.Name}`
        }
      } else if (this.relatedto === 'Advisor') {
        return {
          Value: row.ID,
          Title: `${row.ShortID} - ${row.Name}(${row.OrganizationName})`
        }
      } else if (this.relatedto === 'Settings') {
        return {
          Value: row.ID,
          Title: `${row.Year}`
        }
      } else if (this.relatedto === 'User') {
        return {
          Value: row.ID,
          Title: `${row.FullName} <${row.Username}>`
        }
      } else if (this.relatedto === 'Fee') {
        return {
          Value: row.ID,
          Title: `${row.Description}`
        }
      } else if (this.relatedto === 'Pledge') {
        const pensionCapital = new Intl.NumberFormat('sv-SE', { style: 'currency', currency: 'SEK' }).format(
          row.PensionCapital
        )
        return {
          Value: row.ID,
          Title: `${row.ShortID} | ${row.Issued.substring(0, 10)} | ${row.AccountName} | ${pensionCapital}`
        }
      } else if (this.relatedto === 'Payout') {
        const date = this.$moment(row.CreatedAt)
        return {
          Value: row.ID,
          Title: `${date.format('YYYY-MM-DD HH:mm')} | ${row.ID}`
        }
      }
      return {
        Value: row.ID,
        Title: row.ShortID
      }
    },
    refresh(id) {
      return this.search()
      // if (id === null) {
      //   return this.search()
      // }
      // this.singleProvider({ filters: new Map([['ID', [id]]]) }).then(res => {
      //   this.choices = [res].map(this.choiceResolver)
      // })
    }
  },
  computed: {
    apiClient() {
      if (this.$store.getters.api === null) {
        return null
      }
      if (this.relatedtocommon) {
        return this.$store.getters.api.common()
      } else {
        return this.$store.getters.api.trust(this.$route.params.trust)
      }
    },
    listProvider() {
      if (this.apiClient === null) {
        return null
      }
      return this.apiClient.listProvider(this.relatedto)
    },
    singleProvider() {
      if (this.apiClient === null) {
        return null
      }
      return this.apiClient.singleProvider(this.relatedto)
    },
    fieldsProvider() {
      if (this.$store.getters.api === null) {
        return null
      }
      return this.apiClient.fieldsProvider(this.relatedto)
    },
    cSearchOptions() {
      const options = {
        DisableStrictNumericSearch: false,
        IgnoredSearchFields: ['ID', 'ShortID']
      }

      // Overwrite defaults if they are set
      for (const key in this.searchOptions) {
        if (this.searchOptions[key] != null) {
          options[key] = this.searchOptions[key]
        }
      }

      return options
    },
    cListOnSearch() {
      if (this.filters?.length > 0 && !this.searching && this.choices.length > 1) {
        return false // forces the list to show when filters are present
      }

      return this.listOnSearch
    }
  },
  watch: {
    value() {
      if (this.value !== '') {
        this.refresh(this.value)
      }
    },
    filters() {
      const useDefaultSelector =
        !this.oldFilters || _.differenceWith(this.filters, this.oldFilters, _.isEqual).length > 0
      this.search('', useDefaultSelector)
      this.oldFilters = [...this.filters]
    }
  },
  mounted() {
    if (this.value && this.value !== '') {
      this.refresh(this.value)
    } else {
      this.search('', this.preselect)
    }
  },
  components: {
    fieldSelect
  }
}
</script>

<style scoped>
button {
  color: white;
  background-color: #ff6c6c;
  border-radius: 6px;
  border: 0;
  margin-left: 1rem;
}
</style>
