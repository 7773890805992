<template>
  <article class="message" :class="type">
    <div class="message-header">
      <p>{{ title }}</p>
      <button v-if="closable" class="delete" aria-label="delete"></button>
    </div>
    <div class="message-body">
      <slot></slot>
    </div>
  </article>
</template>

<script>
export default {
  name: 'bulma-message',
  props: {
    title: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'is-primary'
    },
    closable: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style scoped lang="scss">
@import '~bulma';
</style>
